import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function formatCurrency(
  value = 0,
  decimal = 2,
  symbol = '',
  prefix = false
) {
  const trimmedValue = Intl.NumberFormat('en-US').format(
    Number(Number(value).toFixed(decimal))
  );
  return prefix ? `${symbol} ${trimmedValue}` : `${trimmedValue} ${symbol}`;
}
