import {
  Autocomplete,
  Box,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { TextFieldComponent, TextFieldSelect } from "../../../../components";
import { Button } from "rsuite";
import SearchNotFound from "../../../../components/SearchNotFound";
import { merchantApi } from "../../../../redux/api/apiConfig";
import { INCOMING_TRANSFER } from "../../../../ApiUrl";
import { toast } from "react-toastify";

const UpdateInvoiceReceiptInfo = ({ details, supplierList, getTransferDetail }) => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      paid_by: details?.paid_by,
      cobo: {
        id: details?.cobo?.id,
        business_name: details?.cobo?.name,
      },
    },
    onSubmit: addClickHandler,
  });

  async function addClickHandler(values) {
    const requestedData = {
      ...values,
      cobo: values?.cobo?.id,
    };

    try {
      setLoading(true);
      const response = await merchantApi.patch(
        `${INCOMING_TRANSFER}${details?.id}/`,
        requestedData
      );
      toast.success("Details Updated");
      setLoading(false);
      getTransferDetail();
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(err?.data?.data?.message || "Something went wrong");
    }
  }

  return (
    <Box sx={{ mt: 3 }}>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "2fr 2fr 1fr",
            gap: "20px",
            alignItems: "end",
          }}
        >
          <TextFieldComponent
            formik={formik}
            name={"paid_by"}
            type={"text"}
            label={"Paid By"}
          />
          <Autocomplete
            disablePortal
            sx={{ flexGrow: 1, width: "100%" }}
            id="search"
            getOptionLabel={(option) => {
              return option?.business_name;
            }}
            value={formik.values.cobo}
            isOptionEqualToValue={(option, value) => {
              return option?.id === value?.id;
            }}
            options={supplierList}
            onInputChange={(e, value) => setInputValue(value)}
            onChange={(e, value) => {
              if (value) {
                formik.setFieldValue("cobo", value);
              }
            }}
            noOptionsText={<SearchNotFound searchQuery={inputValue} />}
            renderInput={(params) => {
              return (
                <Stack
                  direction={"column"}
                  gap={1}
                  sx={{
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexGrow: 1,
                  }}
                  spacing={0}
                >
                  <Typography>COBO*</Typography>
                  <TextField
                    {...params}
                    variant="outlined"
                    name={"ref_customer"}
                    // placeholder={`Search Sub Account`}
                    type="text"
                    onBlur={formik?.handleBlur}
                    error={
                      formik?.touched?.cobo && Boolean(formik?.errors?.cobo)
                    }
                    helperText={formik?.touched?.cobo && formik?.errors?.cobo}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#1a1f64", // default border color
                          borderWidth: "2px",
                        },
                      },
                    }}
                  />
                </Stack>
              );
            }}
            renderOption={(props, option) => {
              const { id } = option;
              return (
                <li {...props} key={id}>
                  <Typography variant="body2">
                    {option?.business_name}
                  </Typography>
                </li>
              );
            }}
          />
          <Button
            className={`btn action-button primary_color text-white`}
            appearance="primary"
            type={"submit"}
            // disabled={!formik.isValid}
            loading={loading}
          >
            Update
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default UpdateInvoiceReceiptInfo;
