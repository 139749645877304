import {
  Autocomplete,
  Box,
  Card,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { TextFieldComponent } from "../../../components";
import SearchNotFound from "../../../components/SearchNotFound";
import { Button } from "rsuite";
import { merchantApi } from "../../../redux/api/apiConfig";
import { format } from "date-fns";
import { CONTRACTS } from "../../../ApiUrl";

const ContractInfo = ({ supplierList, lenderList, selectedContract }) => {
  const [loading, setLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState({
    date: null,
    ref_customer: null,
    ref_lender: null,
    initial_remarks: "",
    amount: "",
    invoice_value: "",
    invoice_client: "",
  });
  const [inputValue, setInputValue] = useState("");
  const validationSchema = yup.object({
    ref_customer: yup.object().required("Supplier is required").nullable(),
    date: yup
      .date("Enter a valid date")
      .max(new Date(), "Contract Date cannot be in the future")
      .required("Contract Date is required")
      .nullable(),
    amount: yup
      .string("Enter amount")
      .min(0, "Please enter valid amount")
      .required("Amount is required!"),
    initial_remarks: yup.string().required("Initial Remark is required!"),
  });

  const addClickHandler = async (values) => {
    const updatedDataObj = {};
    const defaultValues = formik?.initialValues;
    const keys = Object.keys(defaultValues);
    keys.forEach((key) => {
      if (defaultValues[key] !== values[key]) {
        if (key === "ref_customer") {
          updatedDataObj[key] = values[key]?.id;
        } else if (key === "ref_lender") {
          updatedDataObj[key] = values[key]?.id;
        } else if (key === "date") {
          updatedDataObj[key] = format(new Date(values[key]), "yyyy-MM-dd");
        } else {
          updatedDataObj[key] = values[key];
        }
      }
    });
    try {
      setLoading(true);
      const response = await merchantApi.patch(
        `${CONTRACTS}${selectedContract?.id}/`,
        updatedDataObj
      );
      toast.success("Contract Updated Successfully");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(err?.data?.data?.message || "Something went wrong");
    }
  };

  const formik = useFormik({
    initialValues: defaultValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: addClickHandler,
  });

  useEffect(() => {
    if (selectedContract) {
      setDefaultValues({
        date: new Date(selectedContract?.date),
        ref_customer: {
          id: selectedContract?.customer?.id,
          business_name: selectedContract?.customer?.name,
        },
        ref_lender: selectedContract?.ref_lender
          ? {
              id: selectedContract?.lender?.id,
              business_name: selectedContract?.lender?.name,
            }
          : null,
        initial_remarks: selectedContract?.initial_remarks,
        amount: selectedContract?.amount,
        invoice_value: selectedContract?.invoice_value,
        invoice_client: selectedContract?.invoice_client,
      });
    }
  }, [selectedContract]);
  return (
    <Card
      sx={{
        p: 2,
        mt: 3,
        borderRadius: "16px",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.8),0px 1px 1px 0px rgba(0,0,0,0.5),0px 1px 3px 0px rgba(0,0,0,0.5)",
      }}
    >
      <Typography sx={{ fontSize: "22px", fontWeight: 600, color: "#1a1f64" }}>
        Update Contract Details
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: "20px",
            mt: 5,
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "20px",
            }}
          >
            <DatePicker
              fullWidth
              disableFuture
              format={"dd/MM/yyyy"}
              className="mb-4 bg-white"
              id={"date"}
              name="date"
              value={formik.values.date}
              label="Contract Date*"
              onChange={(date) => {
                formik.setFieldTouched("date", true);
                formik.setFieldValue("date", date);
              }}
              slots={{
                textField: TextField,
              }}
              slotProps={{
                textField: {
                  variant: "outlined",
                  fullWidth: true,
                  error: formik.touched.date && Boolean(formik.errors.date),
                  helperText: formik.touched.date && formik.errors.date,
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#1a1f64", // default border color
                    borderWidth: "2px",
                  },
                },
              }}
            />
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "20px",
            }}
          >
            <Autocomplete
              disablePortal
              sx={{ flexGrow: 1, width: "100%" }}
              id="search"
              getOptionLabel={(option) => {
                return option?.business_name;
              }}
              value={formik.values.ref_customer}
              isOptionEqualToValue={(option, value) => {
                return option?.id === value?.id;
              }}
              options={supplierList}
              onInputChange={(e, value) => setInputValue(value)}
              onChange={(e, value) => {
                if (value) {
                  formik.setFieldValue("ref_customer", value);
                }
              }}
              noOptionsText={<SearchNotFound searchQuery={inputValue} />}
              disabled={true}
              renderInput={(params) => {
                return (
                  <Stack
                    direction={"column"}
                    gap={1}
                    sx={{
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      flexGrow: 1,
                    }}
                    spacing={0}
                  >
                    <Typography>Supplier*</Typography>
                    <TextField
                      {...params}
                      variant="outlined"
                      name={"ref_customer"}
                      // placeholder={`Search Sub Account`}
                      type="text"
                      onBlur={formik?.handleBlur}
                      error={
                        formik?.touched?.ref_customer &&
                        Boolean(formik?.errors?.ref_customer)
                      }
                      helperText={
                        formik?.touched?.ref_customer &&
                        formik?.errors?.ref_customer
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#1a1f64", // default border color
                            borderWidth: "2px",
                          },
                        },
                      }}
                    />
                  </Stack>
                );
              }}
              renderOption={(props, option) => {
                const { id } = option;
                return (
                  <li {...props} key={id}>
                    <Typography variant="body2">
                      {option?.business_name}
                    </Typography>
                  </li>
                );
              }}
            />

            <Autocomplete
              disablePortal
              sx={{ flexGrow: 1, width: "100%" }}
              id="search"
              getOptionLabel={(option) => {
                return option?.business_name;
              }}
              value={formik.values.ref_lender}
              isOptionEqualToValue={(option, value) => {
                return option?.id === value?.id;
              }}
              options={lenderList}
              onInputChange={(e, value) => setInputValue(value)}
              onChange={(e, value) => {
                if (value) {
                  formik.setFieldValue("ref_lender", value);
                }
              }}
              noOptionsText={<SearchNotFound searchQuery={inputValue} />}
              renderInput={(params) => {
                return (
                  <Stack
                    direction={"column"}
                    sx={{
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      flexGrow: 1,
                    }}
                    gap={1}
                  >
                    <Typography>Lender (Optional)</Typography>
                    <TextField
                      {...params}
                      variant="outlined"
                      name={"ref_lender"}
                      // placeholder={`Search Sub Account`}
                      type="text"
                      onBlur={formik?.handleBlur}
                      error={
                        formik?.touched?.ref_lender &&
                        Boolean(formik?.errors?.ref_lender)
                      }
                      helperText={
                        formik?.touched?.ref_lender &&
                        formik?.errors?.ref_lender
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#1a1f64", // default border color
                            borderWidth: "2px",
                          },
                        },
                      }}
                    />
                  </Stack>
                );
              }}
              renderOption={(props, option) => {
                const { id } = option;
                return (
                  <li {...props} key={id}>
                    <Typography variant="body2">
                      {option?.business_name}
                    </Typography>
                  </li>
                );
              }}
            />

            <TextFieldComponent
              formik={formik}
              name={"amount"}
              type={"text"}
              label={"Amount*"}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">USD</InputAdornment>
                ),
              }}
            />
            <TextFieldComponent
              formik={formik}
              name={"invoice_value"}
              type={"text"}
              label={"Invoice Value (Optional)"}
            />
            <TextFieldComponent
              formik={formik}
              name={"invoice_client"}
              type={"text"}
              label={"Invoice Client Name (Optional)"}
            />
            <TextFieldComponent
              formik={formik}
              name={"initial_remarks"}
              type={"text"}
              label={"Initial Remarks*"}
              multiline
              rows={4}
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            className={`btn action-button primary_color text-white`}
            appearance="primary"
            type={"submit"}
            disabled={!formik.isValid}
            loading={loading}
          >
            Update Contract
          </Button>
        </Box>
      </form>
    </Card>
  );
};

export default ContractInfo;
