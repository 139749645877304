import React, { useEffect, useState } from "react";
import { Box, MenuItem, useTheme } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button } from "rsuite";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { TextFieldComponent, TextFieldSelect } from "../../../../components";
import { toast } from "react-toastify";
import { merchantApi } from "../../../../redux/api/apiConfig";
import * as API from "../../../../ApiUrl";

const numricRegex = /(?=.*[0-9])/;
const validationSchema = yup.object({
  status: yup.string("Select Status").required("Status is required"),
});

const AddRemarksModal = ({
  onClose,
  isOpen,
  data,
  id,
  isContract,
  getTransferTransactionList,
}) => {
  //   console.log("status data", data);
  const theme = useTheme();
  const [loading, setloading] = useState(false);
  const [kycStatus, setKycStatus] = useState(
    data?.map((item) => ({ name: item?.display, value: item?.value }))
  );

  const onSubmit = async () => {
    const data = { ...formik.values };
    console.log(data);
    // try {
    //   setloading(true);
    //   const url = isContract ? API.CONTRACT_PAYMENT : API.CUSTOMER_PAYMENT;
    //   const respose = await merchantApi.patch(`${url}${id}/`, data);
    //   toast.success("Status Updated successfully");
    //   setloading(false);
    //   getTransferTransactionList();
    //   onClose();
    // } catch (error) {
    //   setloading(false);
    //   toast.error(
    //     error?.response?.data?.message || "Error while updating status"
    //   );
    // }
  };

  const formik = useFormik({
    initialValues: {
      status: "",
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit,
    validateOnMount: true,
  });

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={"sm"}
        onClose={onClose}
        scroll={"body"}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontSize: "24px !important",
            color: theme.palette.common.accentColor,
          }}
        >
          Add New Remark
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent
            sx={{
              paddingTop: "0px !important",
              paddingBottom: "0px !important",
            }}
          >
            <Box sx={{ mb: 2 }}>
              <TextFieldComponent
                formik={formik}
                name={"remark"}
                type={"text"}
                label={"Remark*"}
              />
            </Box>
            <DialogActions sx={{ justifyContent: "space-between" }}>
              <Button
                className="btn white-btn action-button "
                onClick={() => {
                  formik.resetForm();
                  onClose();
                }}
                appearance="subtle"
              >
                Cancel
              </Button>
              <Button
                className={`btn action-button primary_color text-white`}
                appearance="primary"
                type={"submit"}
                disabled={!formik.isValid}
                loading={loading}
              >
                Add Remark
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
};

export default AddRemarksModal;
