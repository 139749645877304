import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Button,
  FormCheck,
  Container,
} from "@themesberg/react-bootstrap";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { MenuItem, CircularProgress } from "@material-ui/core";
import { useFormik } from "formik";
import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import * as yup from "yup";
import axios from "axios";
import { authApi } from "../../redux/api/apiConfig";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/x-date-pickers";

import { format } from "date-fns";

import staricon from "../../assets/img/icons/staricon.svg";
import avatars from "../../assets/img/icons/avatars.svg";
import stblogo from "../../assets/img/icons/InvestvertexWhite.png";
import ipusalogo from "../../assets/img/ip_transparent_logo.png";
import back from "../../assets/img/icons/back.svg";
import tick from "../../assets/img/icons/tick.svg";
import mail from "../../assets/img/icons/mail.svg";
import {
  TextFieldComponent,
  PhoneNumberInput,
  TextFieldSelect,
  CountrySelect,
} from "../../components";

import * as API from "../../ApiUrl";
import Image from "../../components/image";

const todayDate = format(new Date(), "MM/dd/yyyy");
const minDate = format(new Date("01/01/1900"), "MM/dd/yyyy");

const userValidationSchema = yup.object({
  first_name: yup
    .string("Enter your first name")
    .min(2, "Please enter your legal first name")
    .required("First name is required"),

  last_name: yup
    .string("Please enter your legal last name")
    .required("Last name is required"),

  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),

  dob: yup
    .date("Enter date of birth")
    .required("Date of birth is required")
    .max(todayDate, "DOB cannot be future date")
    .min(minDate, "Invalid date"),

  // phone_number: yup
  //   .number('Enter phone number')
  //   .required('Phone number is required')
  //   .integer(),
  // document_type: yup
  //   .string('Select document type')
  //   .required('Document type is required'),
  // document_number: yup
  //   .string('Select document number')
  //   .required('Document number is required'),
});

const businessValidationSchema = yup.object({
  mto_name: yup
    .string("Enter your business name")
    .min(2, "Please enter you real name")
    .required("Business name is required"),

  phone_number: yup
    .number("Enter phone number")
    .required("Phone number is required")
    .integer(),
  country: yup.string("Select country").required("Country is required"),
  province: yup.string("Select province").required("Province is required"),
  city: yup.string("Enter city").required("City is required"),
  postal_code: yup
    .string(" Enter postal code")
    .required("Postal code is required"),
  address: yup.string("Enter address").required("Address is required"),

  acceptTerms: yup
    .boolean()
    .oneOf([true], "Accept Terms & Conditions is required"),
});

export default ({ changepage, countryList, categoryList, occupationList }) => {
  const history = useHistory();
  // console.log(countryList);

  const countryCodes = countryList.reduce((acc, cur) => {
    acc.push(cur.code2);
    return acc;
  }, []);

  const countryCodesForPhone = countryCodes.map((code) => code.toLowerCase());

  const [error, setError] = useState(null);
  const [loading, setloading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [currentSection, setCurrentSection] = useState("user");
  const [businessCountry, setBusinessCountry] = useState(null);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [provinceList, setProvinceList] = useState([]);
  const [render, setRender] = useState(0);
  const [ipInfo, setIpInfo] = useState(null);

  const [cityList, setCityList] = useState([]);

  const getProvince = async (country) => {
    if (country) {
      try {
        const response = await authApi.get(
          `${API.PROVINCE_LIST}?country=${country}&limit=1000`
        );
        console.log(response);
        setProvinceList(response.data.results);
        const provinces = response.data.results;
        if (ipInfo?.province) {
          const geoLocationProvince = _.find(provinces, {
            geoname_id: ipInfo?.province?.geonameId,
          });
          console.log(geoLocationProvince);
          setSelectedProvince(geoLocationProvince?.id);
          businessFormik.setFieldValue("province", geoLocationProvince?.id);
          setIpInfo((prev) => ({ ...prev, province: null }));
          // setIpInfo(prev => ({...prev,localityInfo:{administrative[1]: null} }))
        }
      } catch (err) {
        toast.error(err?.data?.data?.message || "Something went wrong");
        console.log(err);
      }
    }
  };
  const getCities = async (country) => {
    console.log(country, selectedProvince);
    if (country && selectedProvince) {
      try {
        const response = await authApi.get(
          `${API.CITY_LIST}?country=${country}&region=${selectedProvince}&limit=1000`
        );
        console.log(response, ipInfo);
        const cities = response.data.results;
        setCityList(cities);
        if (ipInfo?.city) {
          const geoLocationCity = _.find(cities, {
            geoname_id: ipInfo?.city?.geonameId,
          });
          businessFormik.setFieldValue("city", geoLocationCity?.id);
          setIpInfo((prev) => ({ ...prev, city: null }));
        }
      } catch (err) {
        console.log(err);
        toast.error(err?.data?.data?.message || "Something went wrong");
      }
    }
  };
  const getTimezones = async () => {
    try {
      const response = await authApi.get(API.TIMEZONES);
      console.log(response);
      console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
      const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const isTimezoneExistInList = response.data.data.find(
        (timezone) => timezone.value === browserTimezone
      );

      if (isTimezoneExistInList) {
        businessFormik.setFieldValue("timezone", {
          value: browserTimezone,
        });
      } else if (browserTimezone === "Asia/Calcutta") {
        businessFormik.setFieldValue("timezone", {
          value: "Asia/Kolkata",
        });
      }
      setTimezoneOptions(response.data.data);
    } catch (err) {
      toast.error(err?.data?.data?.message || "Something went wrong");
      console.log(err);
    }
  };
  useEffect(() => {
    // if (render !== 0) {
    getProvince(businessCountry);
    setProvinceList([]);
    setCityList([]);
    businessFormik.setFieldValue("province", "");
    businessFormik.setFieldValue("city", "");
    // }
  }, [businessCountry]);

  useEffect(() => {
    // if (render !== 0) {
    getCities(businessCountry);
    setCityList([]);
    businessFormik.setFieldValue("city", "");
    // }
  }, [selectedProvince]);

  const onSubmit = async () => {
    let requestData = {
      first_name: userFormik.values.first_name,
      last_name: userFormik.values.last_name,
      email: userFormik.values.email,
      dob: format(new Date(userFormik.values.dob), "yyyy-MM-dd"),

      mto: {
        name: businessFormik.values.mto_name,
        phone_number: businessFormik.values.phone_number,
        country: businessCountry,
        province: businessFormik.values.province,
        city: businessFormik.values.city,
        address: businessFormik.values.address,
        postal_code: businessFormik.values.postal_code,
        timezone: businessFormik.values.timezone.value,
      },
    };
    console.log(requestData);

    setloading(true);
    try {
      const res = await axios.post(`${API.SIGNUP_API}`, requestData);
      console.log(res);
      // localStorage.setItem('user-info', JSON.stringify(res.data));
      // localStorage.setItem('token', res.data.token);

      history.push("/signupthanks", { response: res.data });
      userFormik.resetForm();
      // const Toast = Swal.mixin({
      //   toast: true,
      //   position: 'center',
      //   showConfirmButton: false,
      //   timer: 3000,
      //   timerProgressBar: true,
      //   onOpen: (toast) => {
      //     toast.addEventListener('mouseenter', Swal.stopTimer);
      //     toast.addEventListener('mouseleave', Swal.resumeTimer);
      //   },
      // });

      // Toast.fire({
      //   icon: 'success',
      //   title: 'Signup is successfull',
      // });
    } catch (err) {
      console.log(err, err.response.data.message);
      toast.error(err.data.data.message);
      // err.response.data.message.forEach((message) => toast.error(message));
    }
    setloading(false);
  };

  const togglePage = () => {
    setCurrentSection((prevState) =>
      prevState === "user" ? "business" : "user"
    );
  };

  const userFormik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      dob: undefined,
    },
    validateOnMount: true,
    validateOnBlur: true,
    onSubmit: togglePage,
    validationSchema: userValidationSchema,
  });

  const businessFormik = useFormik({
    initialValues: {
      mto_name: "",
      phone_number: "",
      country: "Country",
      province: "",
      city: "",
      timezone: { value: "US/Central" },
      postal_code: "",
      address: "",
      acceptTerms: false,
    },
    validateOnMount: true,
    validateOnBlur: true,
    onSubmit: onSubmit,
    validationSchema: businessValidationSchema,
  });

  useEffect(() => {
    getTimezones();
    const showPosition = async (position) => {
      console.log(position);
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      const url = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`;
      const params = {
        latitude,
        longitude,
        localityLanguage: "en",
      };
      try {
        const response = await axios.get(process.env.REACT_APP_LOCATION_URL, {
          params,
        });
        const data = response.data;
        const countryCode = data?.countryCode ?? "us";
        setIpInfo({
          country: countryCode,
          province: data.localityInfo.administrative[1],
          // city: data.localityInfo.administrative[3],
          city: data.localityInfo.informative[4],
        });
        console.log({ data });

        const geoLocationBaseAddress = data.localityInfo.administrative
          .reverse()
          .reduce((prev, curr) => {
            console.log(prev, curr);
            if (!prev || !curr.name) {
              return curr.name;
            }
            return `${prev}, ${curr.name}`;
          }, "");

        businessFormik.setFieldValue("address", geoLocationBaseAddress);

        let countryId = _.find(countryList, {
          code2: countryCode,
        })?.id;

        businessFormik.setFieldValue("phone_number", countryCode.toLowerCase());
        console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
        setBusinessCountry(countryId);
        businessFormik.setFieldValue("country", countryCode);
      } catch (error) {
        toast.error(error?.data?.data?.message || "Something went wrong");
        console.log(error);
      }

      // fetch(url)
      //   .then((response) => response.json())
      //   .then((data) => {
      //     console.log(data);
      //     const countryCode = data?.countryCode ?? 'us';
      //     setIpInfo({
      //       country: countryCode,
      //       province: data.localityInfo.administrative[1],
      //       city: data.localityInfo.administrative[3],
      //     });
      //     console.log({ data });

      //     const geoLocationBaseAddress = data.localityInfo.administrative
      //       .reverse()
      //       .reduce((prev, curr) => {
      //         console.log(prev, curr);
      //         if (!prev || !curr.name) {
      //           return curr.name;
      //         }
      //         return `${prev}, ${curr.name}`;
      //       }, '');

      //     businessFormik.setFieldValue('address', geoLocationBaseAddress);

      //     let countryId = _.find(countryList, {
      //       code2: countryCode,
      //     }).id;

      //     businessFormik.setFieldValue(
      //       'phone_number',
      //       countryCode.toLowerCase()
      //     );
      //     // businessFormik.setFieldValue('timezone', { value: 'Asia/Kolkata' });
      //     console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
      //     setBusinessCountry(countryId);
      //     businessFormik.setFieldValue('country', countryCode);
      //   })
      //   .catch((error) => {
      //     console.log(`Error: ${error}`);
      //   });
    };
    if (navigator.geolocation) {
      const permissionDenied = (a) => console.log(a);
      navigator.geolocation.getCurrentPosition(showPosition, permissionDenied);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  console.log(businessFormik);
  return (
    <section className="bg-white">
      <Container fluid className="h-100 stb-w-100 pl-0">
        <div sm={2} className="signup">
          <section className="p-4 stb-info d-flex justify-content-center align-items-center position-relative bg-primary">
            <Stack sx={{ position: "absolute", top: "1rem", left: "1rem" }}>
              <Image
                src={ipusalogo}
                sx={{
                  width: "120px",
                  height: "120px",
                }}
              />
            </Stack>
            {/* <img className='logo' src={stblogo} alt='logo' /> */}
            {/* <a
              className="socials twitter"
              href="https://twitter.com/Spend_The_Bits"
              target="_blank"
              rel="noopener noreferrer"
            >
              @Spend_The_Bits
            </a>
            <a
              className="socials mail"
              href="mailto:help@spendthebits.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={mail} alt="mail" />
              help@spendthebits.com
            </a> */}
            <div class="content-block">
              <div className="stars">
                <img src={staricon} alt="star" />
                <img src={staricon} alt="star" />
                <img src={staricon} alt="star" />
                <img src={staricon} alt="star" />
                <img src={staricon} alt="star" />
              </div>
              <h1>
                XRP Ledger based secure and fast
                <br /> transaction platform.
              </h1>
              <div className="avatars">
                <img src={avatars} alt="avatars" />
                <span>Join 40,000+ users</span>
              </div>
            </div>
          </section>
          <section className="d-flex justify-content-center align-items-center ">
            <div className="bg-white signup-form">
              <Row className="w-75 mt-4">
                <div className="status mb-4">
                  <div className="part">
                    <div className="circle filled">
                      {currentSection === "user" ? (
                        1
                      ) : (
                        <img src={tick} alt="tick" />
                      )}
                    </div>
                    <span className="title">Your Account</span>
                  </div>
                  <div
                    className={`line ${
                      currentSection !== "user" ? "solid" : ""
                    }`}
                  ></div>
                  <div className="part">
                    <div
                      className={`circle  ${
                        currentSection === "user" ? "" : "filled"
                      }`}
                    >
                      2
                    </div>
                    <span className="title">Fill Details</span>
                  </div>
                </div>
                <Col
                  xl={6}
                  xs={12}
                  className="w-100 d-flex align-items-center justify-content-center p-0"
                >
                  <div className="mb-3 mb-lg-0 w-100 ">
                    {/* USER SECTION */}
                    {currentSection === "user" ? (
                      <>
                        <div className="text-left mb-2 mt-4 mt-md-0">
                          <h3 className="mb-0 font-fam text-primary">
                            Signup{" "}
                          </h3>
                          <p className="mt-2 font-fam font-16">
                            Please Fill Your Personal Details For Signup
                          </p>
                        </div>
                        <form onSubmit={userFormik.handleSubmit}>
                          <Box
                            sx={{
                              marginTop: "1rem",
                              display: "grid",
                              columnGap: "16px",
                              rowGap: "12px",
                              gridTemplateColumns: {
                                xs: "repeat(1, 1fr)",
                                sm: "repeat(2, 1fr)",
                              },
                            }}
                          >
                            <TextFieldComponent
                              formik={userFormik}
                              name={"first_name"}
                              label={"First Name"}
                              type={"text"}
                            />

                            <TextFieldComponent
                              formik={userFormik}
                              name={"last_name"}
                              label={"Last Name"}
                              type={"text"}
                            />

                            <TextFieldComponent
                              formik={userFormik}
                              name={"email"}
                              label={"Email"}
                              type={"email"}
                            />

                            <Stack spacing={0} width={"100%"}>
                              <Typography>Date of Birth *</Typography>
                              <Stack>
                                <DatePicker
                                  disableFuture
                                  format={"dd/MM/yyyy"}
                                  value={userFormik.values.dob}
                                  onChange={(value) => {
                                    console.log(value);
                                    userFormik.setTouched("dob");
                                    userFormik.setTouched({
                                      ...userFormik.touched,
                                      dob: true,
                                    });
                                    userFormik.setFieldValue("dob", value);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      name="dob"
                                      id={"dob"}
                                      onChange={(value) => {
                                        console.log(value);
                                        userFormik.setTouched("dob", true);
                                        userFormik.handleChange(value);
                                      }}
                                      onBlur={userFormik.handleBlur}
                                      error={
                                        userFormik.touched.dob &&
                                        Boolean(userFormik.errors.dob)
                                      }
                                      {...params}
                                    />
                                  )}
                                />
                                {userFormik.touched.dob &&
                                  Boolean(userFormik.errors.dob) && (
                                    <p
                                      style={{
                                        color: "#d32f2f",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {userFormik.errors.dob}
                                    </p>
                                  )}
                              </Stack>
                            </Stack>

                            {/* <TextFieldComponent
                              formik={userFormik}
                              name={'dob'}
                              label={'Date of Birth'}
                              type={'date'}
                            /> */}
                          </Box>
                          {error && (
                            <div className=" mb-2  text-danger">
                              <span className="fw-normal">{error}</span>
                            </div>
                          )}

                          <Button
                            variant="primary"
                            disabled={!userFormik.isValid || loading}
                            type={"submit"}
                            className="w-100 font-fam font-100 mt-4"
                          >
                            {loading && <CircularProgress size={14} />}
                            {!loading && "Next"}
                          </Button>

                          <ToastContainer />
                        </form>
                      </>
                    ) : (
                      // BUSINESS SECTION
                      <>
                        <form onSubmit={businessFormik.handleSubmit}>
                          <span onClick={togglePage} className="back">
                            <img src={back} alt="back" />
                            <span>Back</span>
                          </span>
                          <Box
                            sx={{
                              display: "grid",
                              columnGap: "16px",
                              rowGap: "12px",
                              gridTemplateColumns: {
                                xs: "repeat(1, 1fr)",
                                sm: "repeat(2, 1fr)",
                              },
                            }}
                          >
                            <TextFieldComponent
                              formik={businessFormik}
                              name={"mto_name"}
                              label={"MTO Name"}
                              type={"text"}
                            />

                            <CountrySelect
                              formik={businessFormik}
                              label={"Country"}
                              name={"country"}
                              options={countryCodes}
                              countryList={countryList}
                              onChange={(countryId) => {
                                // setRender((prevState) => prevState + 1);
                                setBusinessCountry(countryId);
                              }}
                            />

                            <TextFieldSelect
                              formik={businessFormik}
                              name={"province"}
                              type={"text"}
                              label={"Province"}
                              options={provinceList}
                              onChange={(event) => {
                                // setRender((prevState) => prevState + 1);
                                setSelectedProvince(event.target.value);
                              }}
                              render={(province) => (
                                <MenuItem
                                  value={province?.id}
                                  key={province?.id}
                                >
                                  {province?.name}
                                </MenuItem>
                              )}
                            />
                            <TextFieldSelect
                              formik={businessFormik}
                              name={"city"}
                              type={"text"}
                              label={"City"}
                              options={cityList}
                              render={(city) => (
                                <MenuItem value={city?.id} key={city?.id}>
                                  {city?.name}
                                </MenuItem>
                              )}
                            />

                            <TextFieldComponent
                              formik={businessFormik}
                              name={"postal_code"}
                              label={"Postal Code/Zip Code"}
                              type={"text"}
                            />
                            <PhoneNumberInput
                              formik={businessFormik}
                              label={"Phone Number"}
                              name={"phone_number"}
                              setPhoneNumber={setPhoneNumber}
                              onlyCountries={countryCodesForPhone}
                              country={businessFormik.values.country.toLocaleLowerCase()}
                            />
                          </Box>
                          <Autocomplete
                            id="timezone"
                            value={businessFormik.values.timezone}
                            onChange={(e, newValue) => {
                              console.log(e, newValue);
                              businessFormik.setFieldValue(
                                "timezone",
                                newValue
                              );
                              // businessFormik.handleChange(e);
                            }}
                            options={timezoneOptions}
                            getOptionLabel={(option) => option?.value ?? ""}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            sx={{ flexGrow: 1, width: "100%", my: 2 }}
                            autoSelect={true}
                            renderInput={(params) => {
                              return (
                                <Stack
                                  direction={"column"}
                                  sx={{
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    flexGrow: 1,
                                  }}
                                  // spacing={1}
                                >
                                  <Typography>Timezone</Typography>
                                  <TextField
                                    placeholder="Select Timezone"
                                    {...params}
                                    // size="small"
                                    name="timezone"
                                    onBlur={businessFormik?.handleBlur}
                                    error={
                                      businessFormik?.touched?.timezone &&
                                      Boolean(businessFormik?.errors?.timezone)
                                    }
                                    helperText={
                                      businessFormik?.touched?.timezone &&
                                      businessFormik?.errors?.timezone
                                    }
                                  />
                                </Stack>
                              );
                            }}
                          />

                          <TextFieldComponent
                            formik={businessFormik}
                            name={"address"}
                            label={"Address"}
                            type={"text"}
                            multiline
                            maxRows={4}
                            minRows={4}
                          />

                          <FormCheck
                            type="checkbox"
                            id="acceptTerms"
                            className=" d-flex mt-4 mb-2 "
                          >
                            <FormCheck.Input
                              className="me-2 mb-2"
                              id="acceptTerms"
                              name="acceptTerms"
                              value={businessFormik.values.acceptTerms}
                              onChange={businessFormik.handleChange}
                              onBlur={businessFormik.handleBlur}
                            />
                            <FormCheck.Label
                              forhtml="acceptTerms"
                              style={{ fontWeight: "normal" }}
                            >
                              I agree to the{" "}
                              <Card.Link
                                className="text-primary"
                                href={API.TERMS_CONDITION}
                                target="_blank"
                                style={{ fontWeight: "bold" }}
                              >
                                Terms of Use
                              </Card.Link>{" "}
                              and{" "}
                              <Card.Link
                                href={API.PRIVACY_POLICY}
                                style={{ marginLeft: 0, fontWeight: "bold" }}
                                target="_blank"
                                className="text-primary"
                              >
                                Privacy Policy
                              </Card.Link>
                            </FormCheck.Label>
                          </FormCheck>
                          {error && (
                            <div className=" mb-2  text-danger">
                              <span className="fw-normal">{error}</span>
                            </div>
                          )}

                          <Button
                            variant="primary"
                            disabled={!businessFormik.isValid || loading}
                            type={"submit"}
                            className="w-100 font-fam font-100 mt-2"
                          >
                            {loading && <CircularProgress size={14} />}
                            {!loading && "Sign Up"}
                          </Button>
                        </form>
                      </>
                    )}
                    <div className="text-center mt-4">
                      <span className="fw-normal">
                        Already have an account?
                        <Card.Link
                          onClick={() => changepage("signin")}
                          className="fw-bold"
                        >
                          <span className="text-primary">{` Login`}</span>
                        </Card.Link>
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </Container>
    </section>
  );
};
