import React, { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button } from "rsuite";

import {
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { TextFieldComponent, TextFieldSelect } from "../../components";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { merchantApi } from "../../redux/api/apiConfig";
import { CONTRACTS, FEE, INTERNAL_TRANSFER } from "../../ApiUrl";
import SearchNotFound from "../SearchNotFound";
import { accountTypes, arraysAreEqual } from "../../utils/common";
import { UploadDocument } from "../uploadDocumentModal";
import { AddNewCard } from "../addNewCard";
import { DatePicker } from "@mui/x-date-pickers";
// import { format } from "date-fns";
import UploadContractDocument from "../uploadDocumentModal/UploadContractDocument";
import { DocumentCard } from "../documentCard";
import ContractDocumentCard from "../documentCard/ContractDocumentCard";
import format from "date-fns/format";

const validationSchema = yup.object({
  ref_customer: yup.object().required("Supplier is required").nullable(),
  date: yup
    .date("Enter a valid date")
    .max(new Date(), "Contract Date cannot be in the future")
    .required("Contract Date is required")
    .nullable(),
  amount: yup
    .string("Enter amount")
    .min(0, "Please enter valid amount")
    .required("Amount is required!"),
  initial_remarks: yup.string().required("Initial Remark is required!"),
  contract_document: yup
    .array()
    .min(1, "Add atleast one document")
    .required("Contract Documents are required"),
});

const CreateContractModal = ({
  onClose,
  isOpen,
  supplierList,
  lenderList,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState({
    date: null,
    ref_customer: null,
    ref_lender: null,
    initial_remarks: "",
    amount: "",
    invoice_value: "",
    invoice_client: "",
    contract_document: [],
  });
  const [inputValue, setInputValue] = useState("");
  const [selectedDocument, setSelectedDocument] = useState([]);
  const [showDocumentUpload, setShowDocumentUpload] = useState(false);
  const theme = useTheme();

  const addClickHandler = async (values) => {
    const requestedData = {
      ...values,
      ref_customer: values?.ref_customer?.id,
      ref_lender: values?.ref_lender ? values?.ref_lender?.id : null,
      date: format(new Date(formik?.values?.date), "yyyy-MM-dd"),
    };

    try {
      setLoading(true);
      const response = await merchantApi.post(CONTRACTS, requestedData);
      console.log(response);
      toast.success("Contract Added Successfully");
      setLoading(false);
      setTimeout(() => {
        formik.resetForm();
        setSelectedDocument([]);
        onSuccess();
        onClose();
      }, 1000);
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(err?.response?.data?.message || "Something went wrong");
    }
  };

  const formik = useFormik({
    initialValues: defaultValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: addClickHandler,
  });

  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const toggleDocumentUpload = () => {
    setShowDocumentUpload((prevState) => !prevState);
  };

  useEffect(() => {
    const formattedArr = selectedDocument?.map((val) => ({
      document_name: val?.name,
      attachment: val?.id,
    }));
    // console.log("formatted arr", formattedArr);
    formik.setFieldValue("contract_document", formattedArr);
  }, [selectedDocument]);

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={"md"}
        onClose={handleCloseModal}
        scroll={"body"}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle
          sx={{ textAlign: "center", fontSize: "24px", color: theme.palette.common.accentColor }}
        >
          Create New Contract
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent
            sx={{
              paddingTop: "30px !important",
              paddingBottom: "0 !important",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                }}
              >
                <DatePicker
                  fullWidth
                  disableFuture
                  format={"dd/MM/yyyy"}
                  className="mb-4 bg-white"
                  id={"date"}
                  name="date"
                  value={formik.values.date}
                  label="Contract Date*"
                  onChange={(date) => {
                    formik.setFieldTouched("date", true);
                    formik.setFieldValue("date", date);
                  }}
                  slots={{
                    textField: TextField,
                  }}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      fullWidth: true,
                      error: formik.touched.date && Boolean(formik.errors.date),
                      helperText: formik.touched.date && formik.errors.date,
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#1a1f64", // default border color
                        borderWidth: "2px",
                      },
                    },
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                }}
              >
                <Autocomplete
                  disablePortal
                  sx={{ flexGrow: 1, width: "100%" }}
                  id="search"
                  getOptionLabel={(option) => {
                    return option?.business_name;
                  }}
                  value={formik.values.ref_customer}
                  isOptionEqualToValue={(option, value) => {
                    return option?.id === value?.id;
                  }}
                  options={supplierList}
                  onInputChange={(e, value) => setInputValue(value)}
                  onChange={(e, value) => {
                    if (value) {
                      formik.setFieldValue("ref_customer", value);
                    }
                  }}
                  noOptionsText={<SearchNotFound searchQuery={inputValue} />}
                  renderInput={(params) => {
                    return (
                      <Stack
                        direction={"column"}
                        gap={1}
                        sx={{
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          flexGrow: 1,
                        }}
                        spacing={0}
                      >
                        <Typography>Supplier*</Typography>
                        <TextField
                          {...params}
                          variant="outlined"
                          name={"ref_customer"}
                          // placeholder={`Search Sub Account`}
                          type="text"
                          onBlur={formik?.handleBlur}
                          error={
                            formik?.touched?.ref_customer &&
                            Boolean(formik?.errors?.ref_customer)
                          }
                          helperText={
                            formik?.touched?.ref_customer &&
                            formik?.errors?.ref_customer
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#1a1f64", // default border color
                                borderWidth: "2px",
                              },
                            },
                          }}
                        />
                      </Stack>
                    );
                  }}
                  renderOption={(props, option) => {
                    const { id } = option;
                    return (
                      <li {...props} key={id}>
                        <Typography variant="body2">
                          {option?.business_name}
                        </Typography>
                      </li>
                    );
                  }}
                />
                <Autocomplete
                  disablePortal
                  sx={{ flexGrow: 1, width: "100%" }}
                  id="search"
                  getOptionLabel={(option) => {
                    return option?.business_name;
                  }}
                  value={formik.values.ref_lender}
                  isOptionEqualToValue={(option, value) => {
                    return option?.id === value?.id;
                  }}
                  options={lenderList}
                  onInputChange={(e, value) => setInputValue(value)}
                  onChange={(e, value) => {
                    if (value) {
                      formik.setFieldValue("ref_lender", value);
                    }
                  }}
                  noOptionsText={<SearchNotFound searchQuery={inputValue} />}
                  renderInput={(params) => {
                    return (
                      <Stack
                        direction={"column"}
                        gap={1}
                        sx={{
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          flexGrow: 1,
                        }}
                        spacing={0}
                      >
                        <Typography>Lender (Optional)</Typography>
                        <TextField
                          {...params}
                          variant="outlined"
                          name={"ref_lender"}
                          // placeholder={`Search Sub Account`}
                          type="text"
                          onBlur={formik?.handleBlur}
                          error={
                            formik?.touched?.ref_lender &&
                            Boolean(formik?.errors?.ref_lender)
                          }
                          helperText={
                            formik?.touched?.ref_lender &&
                            formik?.errors?.ref_lender
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#1a1f64", // default border color
                                borderWidth: "2px",
                              },
                            },
                          }}
                        />
                      </Stack>
                    );
                  }}
                  renderOption={(props, option) => {
                    const { id } = option;
                    return (
                      <li {...props} key={id}>
                        <Typography variant="body2">
                          {option?.business_name}
                        </Typography>
                      </li>
                    );
                  }}
                />
                <TextFieldComponent
                  formik={formik}
                  name={"amount"}
                  type={"text"}
                  label={"Amount*"}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">USD</InputAdornment>
                    ),
                  }}
                />
                <TextFieldComponent
                  formik={formik}
                  name={"invoice_value"}
                  type={"text"}
                  label={"Invoice Value (Optional)"}
                />
                <TextFieldComponent
                  formik={formik}
                  name={"invoice_client"}
                  type={"text"}
                  label={"Invoice Client Name (Optional)"}
                />
                <TextFieldComponent
                  formik={formik}
                  name={"initial_remarks"}
                  type={"text"}
                  label={"Initial Remarks*"}
                  multiline
                  rows={4}
                />
              </Box>
              <Stack
                spacing={1}
                direction={"column"}
                alignItems={"center"}
                mb={1}
                gap={1}
              >
                <Typography
                  sx={{
                    textAlign: "left",
                    marginTop: "8px !important",
                    width: "100%",
                  }}
                >
                  Documents*
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    gridGap: "1rem",
                    width: "100%",
                    marginTop: "0 !important",
                  }}
                >
                  {selectedDocument?.length === 0 ? (
                    <AddNewCard onClick={toggleDocumentUpload} />
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(auto-fill, 250px)",
                          gridGap: "20px",
                          width: "100%",
                        }}
                      >
                        {selectedDocument?.map((document, i) => (
                          // <Stack onClick={() => handleOpenLightbox(i)} key={i}>
                          <Stack key={i}>
                            <ContractDocumentCard
                              // isSelected={selectedSenderDocuments.includes(document.id)}
                              documents={document}
                              docName={document?.name}
                              // onDocumentSelect={onDocumentSelect}
                              onDocumentDelete={(id) =>
                                setSelectedDocument((prev) =>
                                  prev?.filter((data) => data?.id !== id)
                                )
                              }
                              canDelete={true}
                            />
                          </Stack>
                        ))}
                        <AddNewCard onClick={toggleDocumentUpload} />
                      </Box>
                    </>
                  )}
                  {selectedDocument.length === 0 && (
                    <Typography
                      variant="caption"
                      sx={{
                        color: `${theme.palette.error.main}`,
                        // mt: 2,
                        // my: 4,
                        fontSize: 14,
                      }}
                    >
                      {formik?.errors?.contract_document}
                    </Typography>
                  )}
                </Box>
              </Stack>
            </Box>

            <DialogActions sx={{ justifyContent: "space-between" }}>
              <Button
                className="btn white-btn action-button "
                onClick={() => {
                  formik.resetForm();
                  setSelectedDocument([]);
                  onClose();
                }}
                appearance="subtle"
              >
                Cancel
              </Button>
              <Button
                className={`btn action-button primary_color text-white`}
                appearance="primary"
                type={"submit"}
                disabled={!formik.isValid}
                loading={loading}
              >
                Create Contract
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
        {showDocumentUpload && (
          <UploadContractDocument
            isOpen={showDocumentUpload}
            onClose={toggleDocumentUpload}
            onDocUpload={(val) => {
              toggleDocumentUpload();
              setSelectedDocument((prev) => [...prev, val]);
            }}
          />
        )}
      </Dialog>
    </>
  );
};

export default CreateContractModal;
