import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  ListSubheader,
  FormControl,
  InputLabel,
  useTheme,
} from "@mui/material";
import { fCurrency } from "../utils/formatNumber";
import { formatWithoutTimezone } from "../utils/common";

const GroupedSelect = ({
  transactionsDetails,
  setSelectedPayment,
  selectedPayment,
}) => {
  const [leagues, setLeagues] = useState({});
  const theme = useTheme();

  // Fetch data from API (mocked for this example)
  useEffect(() => {
    if (transactionsDetails) {
      setLeagues(transactionsDetails);
    }
  }, [transactionsDetails]);

  const handleChange = (event) => {
    // console.log(event.target.value);
    setSelectedPayment(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel
        sx={{
          backgroundColor: `${theme.palette.common.lightAccentColor} !important`,
        }}
      >
        Select Transactions
      </InputLabel>
      <Select
        value={selectedPayment}
        onChange={handleChange}
        label="Select Transactions"
        sx={{
          backgroundColor: theme.palette.common.lightAccentColor,
        }}
      >
        {Object.keys(leagues).map((group) => [
          // Render the group header
          <ListSubheader key={group} sx={{ color: "#000", fontSize: "16px" }}>
            {group === "contract_payment"
              ? "Contract Payment"
              : "Customer Payment"}
          </ListSubheader>,
          // Render the options in the group
          leagues[group].map((league) => (
            <MenuItem
              key={league.id}
              value={JSON.stringify({ id: league.id, type: group })}
              sx={{
                fontSize: "14px",
                paddingBlock: "10px",
                borderBottom: "1px solid grey",
                fontWeight: 500,
              }}
            >
              {group === "contract_payment"
                ? league?.lender?.name
                  ? `${league.lender?.name} - ${fCurrency(
                      league?.amount
                    )} (${formatWithoutTimezone(
                      league.created_date,
                      "dd MMM yyyy"
                    )})`
                  : `${fCurrency(league?.amount)} (${formatWithoutTimezone(
                      league.created_date,
                      "dd MMM yyyy"
                    )})`
                : league?.supplier?.name
                ? `${league.supplier?.name} - ${fCurrency(
                    league?.amount
                  )} (${formatWithoutTimezone(
                    league.created_date,
                    "dd MMM yyyy"
                  )})`
                : `${fCurrency(league?.amount)} (${formatWithoutTimezone(
                    league.created_date,
                    "dd MMM yyyy"
                  )})`}
            </MenuItem>
          )),
        ])}
      </Select>
    </FormControl>
  );
};

export default GroupedSelect;
