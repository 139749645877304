import React, { useState } from "react";
import {
  Iconify,
  Label,
  PreviewContainer,
  PreviewItem,
} from "../../../../components";
import { Box, Button, Stack, useTheme } from "@mui/material";
import UpdateInvoiceReceiptInfo from "../updateInvoiceReceiptInfo/updateInvoiceReceiptInfo";
import { formatWithoutTimezone } from "../../../../utils/common";
import { fCurrency } from "../../../../utils/formatNumber";
import ContractDocumentCard from "../../../../components/documentCard/ContractDocumentCard";
import UpdateClaimStatusModal from "./updateClaimStatusModal";
import AddRemarksModal from "./addRemarksModal";
import back from "../../../../assets/img/icons/back.svg";

const selectedContractFiles = [
  {
    attachment: 68,
    attachment_url:
      "https://ipusa-dev-media.spendthebits.com/private-content/Screenshot_2024-01-20_123944.png",
    document_name: "Contract Document",
    id: 32,
  },
  {
    attachment: 68,
    attachment_url:
      "https://ipusa-dev-media.spendthebits.com/private-content/Screenshot_2024-01-20_123944.png",
    document_name: "Contract Document",
    id: 33,
  },
];

const PaymentClaimDetail = ({ setShowClaimDetail, claimDetails }) => {
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showRemarkModal, setShowRemarkModal] = useState(false);
  const theme = useTheme();
  const businessDetails = (
    <>
      <PreviewItem
        title={"Date of Claim"}
        content={
          claimDetails?.created_date
            ? formatWithoutTimezone(claimDetails?.created_date, "dd MMM yyyy")
            : "--"
        }
      />
      <PreviewItem
        title={"Claim Status"}
        content={
          <>
            <Label
              variant={"ghost"}
              color={
                claimDetails?.status?.value === "approved"
                  ? "complete"
                  : claimDetails?.status?.value === "rejected"
                  ? "rejected"
                  : "application submitted"
              }
              sx={{ textTransform: "capitalize" }}
            >
              {claimDetails?.status?.display}
            </Label>
          </>
        }
      />
      <PreviewItem title={"Customer"} content={claimDetails?.customer?.name} />
      <PreviewItem title={"Claim Message"} content={claimDetails?.message} />
    </>
  );

  const supplierDetails = (
    <>
      <PreviewItem
        title={"Customer Business Name"}
        // content={
        //   details?.created_date
        //     ? formatWithoutTimezone(details?.created_date, "dd MMM yyyy")
        //     : "--"
        // }
        content={"Business A"}
      />
      <PreviewItem
        title={"Customer Email"}
        // content={
        //   details?.created_date
        //     ? formatWithoutTimezone(details?.created_date, "dd MMM yyyy")
        //     : "--"
        // }
        content={"suppliera@gmail.com"}
      />
      <PreviewItem
        title={"KYB Staus"}
        // content={
        //   details?.created_date
        //     ? formatWithoutTimezone(details?.created_date, "dd MMM yyyy")
        //     : "--"
        // }
        content={"Complete"}
      />
    </>
  );
  return (
    <Box
      sx={{
        mt: 3,
        mb: 3,
        display: "flex",
        flexDirection: "column",
        rowGap: "20px",
      }}
    >
      <Stack justifyContent={"space-between"} direction={"row"} gap={2}>
        <span className="back" onClick={() => setShowClaimDetail(false)}>
          <img src={back} alt="back" />

          <span>Back</span>
        </span>
        <Stack justifyContent={"flex-end"} direction={"row"} gap={2}>
          <Button
            variant="contained" // Choose between "contained", "outlined", or "text"
            color="primary"
            type="button"
            style={{
              display: "inline-flex",
              alignItems: "center",
              gap: "10px",
              fontSize: "14px",
              maxWidth: "200px",
            }}
            onClick={() => setShowStatusModal(true)}
          >
            <Iconify icon="radix-icons:update" width={20} />
            Update Claim Status
          </Button>
          {/* <Button
            variant="contained"
            type="button"
            style={{
              display: "inline-flex",
              alignItems: "center",
              gap: "10px",
              fontSize: "14px",
              maxWidth: "200px",
              backgroundColor: theme.palette.common.accentColor,
            }}
            onClick={() => setShowRemarkModal(true)}
          >
            <Iconify icon="material-symbols:add-notes" width={20} />
            Add Remark
          </Button> */}
        </Stack>
      </Stack>
      <PreviewContainer title={"Claim Details"}>
        {businessDetails}
      </PreviewContainer>
      {/* <PreviewContainer title={"Supplier Details"}>
        {supplierDetails}
      </PreviewContainer> */}
      <PreviewContainer title={"Claim Documents"}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(200px, 200px))",
            gridGap: "1rem",
            width: "100%",
          }}
        >
          {claimDetails?.documents?.map((document, i) => {
            const docDetails = {
              id: document?.file_id,
              document: document?.url,
            };
            return <ContractDocumentCard documents={docDetails} key={i} />;
          })}
        </Box>
      </PreviewContainer>
      {/* <UpdateInvoiceReceiptInfo
        details={details}
        supplierList={supplierList}
        getTransferDetail={getTransferDetail}
      /> */}
      {showStatusModal && (
        <UpdateClaimStatusModal
          isOpen={showStatusModal}
          onClose={() => {
            setShowStatusModal(false);
          }}
          data={[
            {
              display: "Approved",
              value: "approved",
            },
            {
              display: "Rejected",
              value: "rejected",
            },
          ]}
          //   id={selectedTransactionStatus?.id}
          //   isContract={currentTab === "contract_payment"}
          //   getTransferTransactionList={getTransferTransactionList}
        />
      )}
      {showRemarkModal && (
        <AddRemarksModal
          isOpen={showRemarkModal}
          onClose={() => {
            setShowRemarkModal(false);
          }}
          //   data={selectedTransactionStatus?.possible_status}
          //   id={selectedTransactionStatus?.id}
          //   isContract={currentTab === "contract_payment"}
          //   getTransferTransactionList={getTransferTransactionList}
        />
      )}
    </Box>
  );
};

export default PaymentClaimDetail;
