import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import "rsuite/dist/styles/rsuite-default.css";

import * as API from "../../ApiUrl";
import { merchantApi } from "../../redux/api/apiConfig";

import { format } from "date-fns";
import {
  Box,
  Divider,
  MenuItem,
  Stack,
  TextField,
  InputAdornment,
  Autocomplete,
  Card,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import usePageTitle from "../../hooks/usePageTitle";
import { contractStatus, reportTimePeriods } from "../reports/common";
import { formatDate } from "../../utils/common";
import { ButtonPrimary, ButtonResetFilter, Iconify } from "../../components";
import { StyledDatePicker } from "../../components/datePicker";
import {
  FilterDropdown,
  Highlight,
  HighlightSkeleton,
} from "../reports/components";
import {
  CLEAR_INWARD_REMITTANCE,
  UPDATE_INWARD_REMITTANCE,
} from "../../redux/actionTypes";
import ClosureTxnsModal from "../../components/closureTxnsModal";
import { ProgrammedLiensTable } from "./components";
import ContractFilesModal from "../../components/contractFilesModal/ContractFilesModal";
import CreateContractModal from "../../components/createContractModal/CreateContractModal";
import { DeleteModal } from "../../components/deleteModal";
import useDeleteModal from "../../hooks/useDeleteModal";

function ProgrammedLiens() {
  const [translist, setTranslist] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [lenderList, setLenderList] = useState([]);
  const [fetchTranslistLoading, setFetchTranslistLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedContractFiles, setSelectedContractFiles] = useState([]);
  const [deleteContractId, setDeleteContractId] = useState(null);
  const [filters, setFilters] = useState({
    period: "",
    search: "",
    date_range_after: "",
    date_range_before: "",
    status: {
      name: "Unsettled",
      value: "unsettled",
    },
    currentPage: 0,
    pageSize: 7,
  });
  const [highlightLoading, setHighlightLoading] = useState(false);
  const [highlights, setHighlights] = useState([
    { heading: "Pending Contracts", data: 23, meta: { color: "#c12e22" } },
    { heading: "Settled Contracts", data: 23, meta: { color: "#449f0b" } },
    {
      heading: "Partially Settled Contracts",
      data: 23,
      meta: { color: "#e9a642" },
    },
  ]);
  const [showContractFilesModal, setShowContractFilesModal] = useState(false);
  const [showClosureTxnsModal, setShowClosureTxnsModal] = useState(false);
  const [counts, setCounts] = useState({
    pending: 0,
    processed: 0,
    all: 0,
  });
  const [showCreateContractModal, setShowCreateContractModal] = useState("");
  const { showDeleteModal, toggleShowDeleteModal } = useDeleteModal();

  usePageTitle("Contracts", "View your contracts");

  const handleClearFilters = () => {
    setFilters({
      period: "",
      search: "",
      date_range_after: "",
      date_range_before: "",
      status: {
        name: "Unsettled",
        value: "unsettled",
      },
      currentPage: 0,
      pageSize: 7,
    });
  };

  useEffect(() => {
    getContractList(filters);
  }, [filters]);

  async function getContractList(filters) {
    setFetchTranslistLoading(true);
    const {
      period,
      currentPage,
      pageSize,
      date_range_after,
      date_range_before,
      search,
      status,
    } = filters;
    let params = {
      offset: currentPage * pageSize,
      limit: pageSize,
      period,
      search,
      status: status?.value,
      date_range_after: formatDate(date_range_after, "yyyy-MM-dd"),
      date_range_before: formatDate(date_range_before, "yyyy-MM-dd"),
    };

    merchantApi
      .get(`${API.CONTRACTS}`, {
        params,
      })
      .then((response) => {
        setFetchTranslistLoading(false);
        if (response.data) {
          setTranslist(response.data);
        }
      })
      .catch((error) => {
        toast.error(
          error?.data?.data?.message || "Error getting contracts list"
        );
        setFetchTranslistLoading(false);
      });
  }

  async function getSupplierList() {
    merchantApi
      .get(`${`${API.CONTRACTS_SUPPLIERS}?type=supplier`}`)
      .then((response) => {
        if (response.data) {
          setSupplierList(response.data);
        }
      })
      .catch((error) => {
        toast.error(
          error?.data?.data?.message || "Error getting supplier list"
        );
      });
  }

  async function getLenderList() {
    merchantApi
      .get(`${`${API.CONTRACTS_SUPPLIERS}?type=lender`}`)
      .then((response) => {
        if (response.data) {
          setLenderList(response.data);
        }
      })
      .catch((error) => {
        toast.error(error?.data?.data?.message || "Error getting lender list");
      });
  }

  async function getHighlightsData() {
    setHighlightLoading(true);
    merchantApi
      .get(`${`${API.CONTRACTS_HIGHLIGHTS}`}`)
      .then((response) => {
        setHighlightLoading(false);
        if (response.data) {
          const data = response?.data?.data;
          setHighlights([
            {
              heading: "Pending Contracts",
              data: data?.unsettled,
              meta: { color: "red" },
            },
            {
              heading: "Settled Contracts",
              data: data?.settled,
              meta: { color: "green" },
            },
            {
              heading: "Partially Settled Contracts",
              data: data?.partial_settled,
              meta: { color: "yellow" },
            },
          ]);
        }
      })
      .catch((error) => {
        setHighlightLoading(false);
        toast.error(error?.data?.data?.message || "Error getting Highlights");
      });
  }

  useEffect(() => {
    getSupplierList();
    getLenderList();
    getHighlightsData();
  }, []);

  const toggleClosureTxnsModal = () => {
    setShowClosureTxnsModal((state) => !state);
  };

  const toggleContractFilesModal = () => {
    setShowContractFilesModal((state) => !state);
  };

  const toggleCreateContractModal = () => {
    setShowCreateContractModal((state) => !state);
  };

  async function onContractDelteHandler(id) {
    setIsLoading(true);
    merchantApi
      .delete(`${API.CONTRACTS}${id}/`)
      .then((response) => {
        setIsLoading(false);
        toast.success("Contract Deleted Successfully!");
        toggleShowDeleteModal();
        getContractList(filters);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.data?.data?.message || "Error Deleting Contract");
      });
  }

  return (
    <>
      <Box sx={{ padding: "0 24px 24px" }}>
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          width={"100%"}
          mb={2}
        >
          <ButtonPrimary
            onClick={toggleCreateContractModal}
            style={{
              maxWidth: "300px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Iconify icon="material-symbols:add" width={22} /> Create New
            Contract
          </ButtonPrimary>
        </Stack>
        {highlightLoading || highlights?.length > 0 ? (
          <Card
            sx={{
              width: "100%",
              background: highlightLoading ? "#fff" : "#1a1f64",
            }}
          >
            <Stack
              direction="row"
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    borderStyle: "solid",
                    borderColor: "#fff",
                    borderWidth: "2px",
                  }}
                />
              }
              sx={{ py: 2 }}
            >
              {highlightLoading
                ? [...Array(1)].map((a) => <HighlightSkeleton />)
                : highlights.map((highlight, i) => {
                    return (
                      <Highlight
                        key={highlight.heading}
                        title={highlight.heading}
                        subTitle={highlight.sub_heading}
                        // total={translist.count}
                        percent={50}
                        value={highlight.data}
                        icon={highlight?.meta?.icon}
                        color={highlight?.meta?.color}
                      />
                    );
                  })}
            </Stack>
          </Card>
        ) : null}
        <Stack
          sx={{
            overflowX: { xs: "auto" },
            overflowY: { xs: "visible", md: "unset" },
            padding: "5px 0",
          }}
          direction={"row"}
          justifyContent={"space-between"}
          gap={1}
          mt={2}
          width={"100%"}
        >
          <Stack
            sx={{ flexDirection: "row", gap: 1 }}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderStyle: "dashed" }}
              />
            }
          >
            <Stack sx={{ minWidth: "150px", flex: 1 }}>
              <FilterDropdown
                name="period"
                type="text"
                label="Time Period"
                options={reportTimePeriods}
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    period: e.target.value,
                  }));
                }}
                value={filters?.period}
                size={"small"}
                render={(period, i) => (
                  <MenuItem key={period.key} value={period.key}>
                    {period.name}
                  </MenuItem>
                )}
              />
            </Stack>
            <Stack sx={{ flexDirection: "row", gap: 1 }}>
              <Stack sx={{ width: "176px", flex: 1 }}>
                <StyledDatePicker
                  value={filters?.date_range_after}
                  disableFuture
                  format={"dd/MM/yyyy"}
                  onChange={(newValue) => {
                    setFilters((prev) => ({
                      ...prev,
                      date_range_after: new Date(
                        format(newValue, "yyyy/MM/dd")
                      ),
                    }));
                  }}
                />
              </Stack>

              <Stack sx={{ width: "176px", flex: 1 }}>
                <StyledDatePicker
                  value={filters?.date_range_before}
                  disableFuture
                  format={"dd/MM/yyyy"}
                  onChange={(newValue) => {
                    setFilters((prev) => ({
                      ...prev,
                      date_range_before: new Date(
                        format(newValue, "yyyy/MM/dd")
                      ),
                    }));
                  }}
                />
              </Stack>
            </Stack>

            <Stack sx={{ flexDirection: "row", gap: 1, flex: 1 }}>
              <Stack sx={{ minWidth: "200px", flex: 1 }}>
                <TextField
                  fullWidth
                  size="small"
                  value={filters.search}
                  onChange={(e) =>
                    setFilters((prev) => ({
                      ...prev,
                      search: e.target.value,
                    }))
                  }
                  placeholder="Search..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify
                          icon="eva:search-fill"
                          sx={{ color: "text.disabled" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>

            <Stack sx={{ minWidth: "200px", flex: 1 }}>
              <Autocomplete
                id="status"
                value={filters.status}
                onChange={(e, newValue) => {
                  setFilters((prev) => ({
                    ...prev,
                    status: newValue,
                  }));
                }}
                options={contractStatus}
                getOptionLabel={(option) => option?.name}
                sx={{ flexGrow: 1, width: "100%" }}
                renderInput={(params) => {
                  return (
                    <TextField
                      placeholder="Select Status"
                      {...params}
                      size="small"
                    />
                  );
                }}
              />
            </Stack>
          </Stack>
          <Stack sx={{ flexDirection: "row", gap: 1 }}>
            <ButtonResetFilter onClick={handleClearFilters} />
          </Stack>
        </Stack>
        <ProgrammedLiensTable
          translist={translist}
          fetchTranslistLoading={fetchTranslistLoading}
          viewDetails={toggleClosureTxnsModal}
          viewContractFiles={toggleContractFilesModal}
          setSelectedContractFiles={(val) => setSelectedContractFiles(val)}
          counts={counts}
          filters={filters}
          setFilters={setFilters}
          onDelete={(id) => {
            setDeleteContractId(id);
            toggleShowDeleteModal();
          }}
        />
        <ClosureTxnsModal
          isOpen={showClosureTxnsModal}
          onClose={toggleClosureTxnsModal}
        />
        <ContractFilesModal
          isOpen={showContractFilesModal}
          onClose={toggleContractFilesModal}
          selectedContractFiles={selectedContractFiles}
        />
        {showCreateContractModal && (
          <CreateContractModal
            isOpen={showCreateContractModal}
            onClose={() => {
              toggleCreateContractModal();
            }}
            onSuccess={() => getContractList(filters)}
            supplierList={supplierList}
            lenderList={lenderList}
          />
        )}

        {showDeleteModal && (
          <DeleteModal
            onClose={toggleShowDeleteModal}
            isOpen={showDeleteModal}
            onSubmit={() => onContractDelteHandler(deleteContractId)}
            text={"Contract"}
            loading={isLoading}
          />
        )}
      </Box>
    </>
  );
}

export default ProgrammedLiens;
