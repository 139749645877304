import React from "react";
import { useHistory } from "react-router-dom";
import { startCase, toLower } from "lodash";
import {
  Box,
  Stack,
  Typography,
  LinearProgress,
  Avatar,
  Tooltip,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import {
  formatWithoutTimezone,
  rowsPerPageOptions,
} from "../../../../utils/common";

import { DataGrid } from "@mui/x-data-grid";
import { formatCurrency, tableCellStyle } from "../../../../utils/common";

import createAvatar from "../../../../utils/createAvatar";
import { Label, ButtonGhost, Iconify } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_INWARD_REMITTANCE } from "../../../../redux/actionTypes";
import InfoIcon from "@mui/icons-material/Info";
import { Routes } from "../../../../routes";
import { fCurrency } from "../../../../utils/formatNumber";
import { format } from "date-fns";

export default function ProgrammedLiensTable({
  translist,
  fetchTranslistLoading,
  viewDetails,
  viewContractFiles,
  counts,
  setSelectedContractFiles,
  setFilters,
  filters,
  onDelete,
}) {
  const history = useHistory();

  const handleChangePage = (newPage) => {
    setFilters((prev) => ({
      ...prev,
      currentPage: newPage,
    }));
  };

  const handleChangeRowsPerPage = (newSize) => {
    setFilters((prev) => ({
      ...prev,
      currentPage: 0,
      pageSize: newSize,
    }));
  };

  const createDataRow = (transaction) => {
    // const type = transaction.type;
    return {
      id: transaction?.id,
      submissionDate: format(new Date(transaction?.date), "dd MMM yyyy"),
      initial_remarks: transaction?.initial_remarks,
      contract_amount: fCurrency(transaction?.amount),
      settled_amount: fCurrency(transaction?.settled_amount),
      lender: transaction?.lender?.name,
      customer: transaction?.customer?.name,
      contract_document: transaction?.contract_document,
      status: transaction?.status,
      action: transaction?.id,
    };
  };

  const rows =
    translist?.results?.length > 0
      ? translist.results.map((transaction) => createDataRow(transaction))
      : [];

  const columns = [
    {
      field: "id",
      headerName: "Contract ID",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
            <Stack
              sx={{ width: "100%", height: "100%", cursor: "pointer" }}
              justifyContent={"center"}
              alignItems={"center"}
              onClick={() => {
                history.push({
                  pathname: Routes.ProgrammedLiensDetailView.path,
                  search: `?id=${params.formattedValue}`,
                });
              }}
            >
              <Typography
                variant="body2"
                style={{ ...tableCellStyle, fontWeight: 600 }}
              >
                {params.formattedValue}
              </Typography>
            </Stack>
        );
      },
    },
    {
      field: "submissionDate",
      headerName: "Date",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "customer",
      headerName: "Customer",
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: "ellipsis",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Stack direction={"row"} sx={{ minWidth: 0, alignItems: "center" }}>
            {/* <Avatar
              alt={params.formattedValue}
              sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}
            >
              {createAvatar(params.formattedValue).name}
            </Avatar> */}
            <Stack>
              <Typography
                variant="body2"
                sx={{
                  color: "black",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: "500",
                }}
              >
                {params?.formattedValue}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "initial_remarks",
      headerName: "Initial Remark",
      flex: 1,
      minWidth: 250,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {`${params.formattedValue}`}
          </Typography>
        );
      },
    },

    {
      field: "contract_amount",
      headerName: "Contract Amount",
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "settled_amount",
      headerName: "Settled Amount",
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: "lender",
      headerName: "Lended By",
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: "ellipsis",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Stack direction={"row"} sx={{ minWidth: 0, alignItems: "center" }}>
            {/* <Avatar
              alt={params.formattedValue}
              sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}
            >
              {createAvatar(params.formattedValue).name}
            </Avatar> */}
            <Stack>
              <Typography
                variant="body2"
                sx={{
                  color: "black",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: "500",
                }}
              >
                {params?.formattedValue || '--'}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "contract_document",
      headerName: "Contract Files",
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <ButtonGhost
            appearance="ghost"
            size="xs"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedContractFiles(params.formattedValue);
              viewContractFiles();
            }}
          >
            View Files
          </ButtonGhost>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      flex: 1.5,
      minWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
            <Label
              variant={"ghost"}
              color={
                params.formattedValue === "fully_settled"
                  ? "complete"
                  : params.formattedValue.toLowerCase() === "unsettled"
                  ? "rejected"
                  : "application submitted"
              }
              sx={{ textTransform: "capitalize" }}
            >
              {params.formattedValue}
            </Label>
          </Stack>
        );
      },
    },
    {
      field: "closureTxn",
      headerName: "Closure Txns",
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <ButtonGhost
            appearance="ghost"
            size="xs"
            onClick={(e) => {
              e.stopPropagation();
              viewDetails();
            }}
          >
            Expand
          </ButtonGhost>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      minWidth: 100,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Box onClick={() => onDelete(params?.formattedValue)}>
            <Iconify
              icon={"ic:baseline-delete"}
              width={20}
              height={20}
              sx={{ color: "red" }}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
          borderRadius: "8px",
          // height: 825,
          width: "100%",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: "#667085",
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
            },
          },
          "& .header": {
            backgroundColor: "#F4F4F4",
            "& div": {
              "& div": {
                "& div": {
                  "& div": {
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  },
                },
              },
            },
          },
        }}
      >
        {/* <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={filters.statusValue}
          onChange={(e, value) => onStatusFilterChanges(value)}
          sx={{
            px: 2,
            bgcolor: "#F4F6F8",
            borderRadius: "8px 8px 0 0",
          }}
        >
          {TABS.map((tab) => (
            <Tab
              sx={{ textTransform: "capitalize" }}
              disableRipple
              key={tab.label}
              value={tab.value}
              icon={
                <Label
                  sx={{ marginRight: "5px", cursor: "pointer" }}
                  variant={"ghost"}
                  color={tab.color}
                >
                  {tab.count}
                </Label>
              }
              label={tab.label}
              iconPosition={"start"}
            />
          ))}
        </Tabs> */}

        <DataGrid
          autoHeight
          sx={{
            borderRadius: "0 0 8px 8px",
            // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
            width: "100%",
            cursor: "pointer",
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          // onRowClick={(row) => {
          //   history.push({
          //     pathname: Routes.ProgrammedLiensDetailView.path,
          //     search: `?id=${row.id}`,
          //   });
          // }}
          rows={rows}
          columns={columns}
          rowHeight={80}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode="server"
          rowsPerPageOptions={rowsPerPageOptions}
          page={filters.currentPage}
          rowCount={translist.count}
          pageSize={filters.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
        />
      </Box>
    </>
  );
}
