import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { startCase, toLower } from "lodash";
import {
  Box,
  Stack,
  Typography,
  LinearProgress,
  Avatar,
  Tooltip,
  Tabs,
  Tab,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import {
  formatWithoutTimezone,
  rowsPerPageOptions,
} from "../../../../utils/common";

import { DataGrid } from "@mui/x-data-grid";
import { formatCurrency, tableCellStyle } from "../../../../utils/common";

import createAvatar from "../../../../utils/createAvatar";
import { Label, ButtonGhost } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_INWARD_REMITTANCE } from "../../../../redux/actionTypes";
import InfoIcon from "@mui/icons-material/Info";
import { Routes } from "../../../../routes";
import { merchantApi } from "../../../../redux/api/apiConfig";
import {
  SUPPLIER_BUS,
  SUPPLIER_BUS_KYB_HISTORY,
  SUPPLIER_BUS_KYC_HISTORY,
} from "../../../../ApiUrl";
import { toast } from "react-toastify";
import KybKycHistoryStatusModal from "../../../../components/kybKycHistoryStatusModal/kybKycHistoryStatusModal";

export default function KybKycStatusHistoryTable({ kybData, kycData }) {
  const [rows, setRows] = useState([]);
  const [viewStatusHistory, setViewStatusHistory] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [kybHistoryData, setKybHistoryData] = useState([]);
  const [kycHistoryData, setKycHistoryData] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const filters = useSelector((state) => state.filters.remittances.inward);

  function createData(id, type, history) {
    return {
      id,
      type,
      history,
    };
  }

  async function getKybHistory(id) {
    try {
      const response = await merchantApi.get(
        `${SUPPLIER_BUS_KYB_HISTORY}${id}/history/`
      );
      console.log(response);
      setKybHistoryData({
        type: "KYB Staus History",
        history: response.data?.history,
      });
      setRows((prev) => [
        ...prev,
        createData(id, "KYB Staus History", response?.data?.data?.history),
      ]);
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
    }
  }
  async function getKycHistory(id, name) {
    try {
      const response = await merchantApi.get(
        `${SUPPLIER_BUS_KYC_HISTORY}${id}/history/`
      );
      console.log(response);
      setKycHistoryData((prev) => [
        ...prev,
        {
          type: `${name} - KYC Staus History`,
          history: response.data?.history,
        },
      ]);
      setRows((prev) => [
        ...prev,
        createData(
          id,
          `${name} - KYC Staus History`,
          response?.data?.data?.history
        ),
      ]);
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
    }
  }

  useEffect(() => {
    if (kybData) {
      getKybHistory(kybData?.id);
    }
  }, [kybData]);

  useEffect(() => {
    if (kycData) {
      kycData.forEach((item) =>
        getKycHistory(item?.kyc?.id, `${item?.first_name} ${item?.last_name}`)
      );
    }
  }, [kycData]);

  console.log("rows data", historyData);

  return (
    <>
      <Box
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
          borderRadius: "8px",
          mt: 3,
          // height: 825,
          width: "100%",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: "#667085",
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
            },
          },
          "& .header": {
            backgroundColor: "#F4F4F4",
            "& div": {
              "& div": {
                "& div": {
                  "& div": {
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  },
                },
              },
            },
          },
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 250 }} aria-label="simple table">
            <TableHead sx={{background: '#f4f4f4'}}>
              <TableRow>
                <TableCell align="left">KYC/KYB</TableCell>
                <TableCell align="left">History</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <>
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{row.type}</TableCell>
                    <TableCell align="left">
                      <ButtonGhost
                        appearance="ghost"
                        size="xs"
                        onClick={() => {
                          setHistoryData(row?.history);
                          setViewStatusHistory(true);
                        }}
                      >
                        View Status History
                      </ButtonGhost>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {viewStatusHistory && (
          <KybKycHistoryStatusModal
            isOpen={viewStatusHistory}
            onClose={() => {
                setViewStatusHistory(false)
                setHistoryData([])
            }}
            data={historyData}
          />
        )}
      </Box>
    </>
  );
}
