import React from 'react';
import { TextField, Stack, Typography } from '@mui/material';

const TextFieldComponent = ({ formik, name, type, label, inputProps = {}, onChange = () => {}, placeholder = '', required = false, ...others }) => {
  return (
    <Stack spacing={0} width={'100%'} gap={1}>
      <Typography >
        {label} {required ? '*' : ''}
      </Typography>
      <TextField
        fullWidth
        variant='outlined'
        placeholder={placeholder}
        name={name}
        id={name}
        type={type}
        value={formik?.values[`${name}`]}
        onChange={(e) => {
          onChange(e);
          formik.handleChange(e);
        }}
        onBlur={formik?.handleBlur}
        error={formik?.touched[`${name}`] && Boolean(formik?.errors[`${name}`])}
        helperText={formik?.touched[`${name}`] && formik?.errors[`${name}`]}
        InputProps={inputProps}
        {...others}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#1a1f64', // default border color
              borderWidth: '2px'
            },
          },
        }}
      />
    </Stack>
  );
};

export default TextFieldComponent;
