import PropTypes from "prop-types";
// @mui
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import ButtonSecondary from "../ButtonSecondary";
import ButtonPrimary from "../ButtonPrimary";
import { Button } from "rsuite";

// ----------------------------------------------------------------------

OtpDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  action: PropTypes.node,
  onClose: PropTypes.func,
  loading: PropTypes.any,
};

export default function OtpDialog({
  title,
  action,
  open,
  onClose,
  onSubmit,
  loading,
  ...other
}) {
  console.log("obwjdcksbdsc", { title, action, open });
  const [otp, setOtp] = useState("");
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

      <DialogContent sx={{ typography: "body2" }}>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          containerStyle={{ width: "100%", justifyContent: "space-evenly" }}
          inputStyle={{ width: "2.5rem", height: "2.5rem" }}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
        />
      </DialogContent>

      <DialogActions>
        <ButtonSecondary
          onClick={() => {
            setOtp("");
            onClose();
          }}
        >
          Cancel
        </ButtonSecondary>
        <Button
          onClick={() => {
            onSubmit(otp);
          }}
          loading={loading}
          className={`btn action-button primary_color text-white `}
        >
          Verify OTP
        </Button>
      </DialogActions>
    </Dialog>
  );
}
