import axios from "axios";
import { BASE_URL, REFRESH_TOKEN_API } from "../../ApiUrl";
import { setSession } from "../../utils/jwt";

let isRefreshing = false;
let refreshPromise = null;

//Auth api
export const authApi = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

authApi.interceptors.request.use(
  async function (options) {
    return options;
  },
  function (error) {
    return Promise.reject(error);
  }
);

authApi.interceptors.response.use(
  async function (options) {
    return options;
  },
  function (error) {
    console.log(error);
    return Promise.reject({ error, data: error.response });
  }
);

//Authorized api
export const merchantApi = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

merchantApi.interceptors.request.use(
  async function (options) {
    // console.log(options);
    // options?.params &&
    // console.log(options, Object.hasOwn(options?.params, 'from_date'));
    options.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    if (
      options.params &&
      (Object.hasOwn(options.params, "created_date_after") ||
        Object.hasOwn(options.params, "from_date") ||
        Object.hasOwn(options.params, "created_date_before") ||
        Object.hasOwn(options.params, "to_date"))
    ) {
      options.params["tz_offset"] = new Date().getTimezoneOffset() / 60;
    }
    return options;
  },
  function (error) {
    return Promise.reject(error);
  }
);

merchantApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is due to an expired token
    if (error.response?.status === 401 && !originalRequest?._retry) {
      if (!isRefreshing) {
        isRefreshing = true;
        originalRequest._retry = true;

        // Use the existing refreshPromise if available
        if (!refreshPromise) {
          refreshPromise = getRefreshToken();
        }

        try {
          const newAccessToken = await refreshPromise;
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return merchantApi(originalRequest);
        } catch (refreshError) {
          setSession(null, null);
          console.log("acacdcsdcsdc", refreshError);
          // Reset the refreshPromise to allow subsequent attempts
          refreshPromise = null;
          window.location.href = "/";
          return Promise.reject(refreshError);
        } finally {
          originalRequest._retry = false;
          isRefreshing = false;
          refreshPromise = null;
        }
      } else {
        // If another request is already refreshing the token, wait for that promise to resolve
        return refreshPromise.then((newAccessToken) => {
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

          // Retry the original request
          return merchantApi(originalRequest);
        });
      }
    }

    console.log(error?.response || error);
    return Promise.reject(error);
  }
);

async function getRefreshToken() {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await authApi.post(REFRESH_TOKEN_API, {
      refresh: refreshToken,
    });
    const { access, refresh } = response.data;
    // GlobalStore.STORE.token = access;
    // GlobalStore.STORE.refresh = refresh;
    // localStorage.setItem("accessToken", access);
    //   localStorage.setItem("refreshToken", refresh);
    setSession(access, refresh);
    return access;
  } catch (e) {
    setSession(null, null);
    isRefreshing = false;
    return Promise.reject(e);
  }
}
