import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "rsuite";
import { Card } from "react-bootstrap";
import { TextFieldComponent, TextFieldPassword } from "../../components";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Stack,
  Paper,
} from "@mui/material";

import {
  getCurrencyTypes,
  getDepositTypes,
  getPurposeTypes,
  getCountriesData,
  getRemittanceStatuses,
  getRedeemStatuses,
  getKycDocTypes,
  getRemittanceTypes,
} from "../../redux/actions/config";
import {
  getPermissions,
  getProfileData,
  verifyOtp,
} from "../../redux/actions/profile";
import { Routes } from "../../routes";
import * as API from "../../ApiUrl";
import ipusalogo from "../../assets/img/ip_transparent_logo.png";
import { getWallets, getCoins } from "../../redux/actions/wallet";
import Image from "../../components/image";
import OtpDialog from "../../components/otpModal/OtpModal";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const Signin = ({ changepage }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [token, setToken] = useState(null);

  console.log(process.env.REACT_APP_STB_ENV);

  const onSubmit = async (values) => {
    console.log(values, "hehe");
    setLoading(true);
    try {
      const response = await axios.post(API.LOGIN_API, {
        ...values,
      });
      localStorage.setItem("user-info", JSON.stringify(response?.data?.data));
      setOpenOtpModal(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(err?.response?.data?.message || "Something went wrong");
    }
  };

  const onCaptchaClick = async (value) => {
    setCaptchaToken(value);
    setIsCaptchaValid(true);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  const onOtpSubmit = async (otp) => {
    try {
      setLoading(true);
      const response = await axios.post(API.LOGIN_VERIFY, {
        otp,
        email: formik?.values?.email,
        password: formik?.values?.password,
      });
      setLoading(false);
      const user = response.data.data;
      const { token } = user;

      localStorage.setItem("accessToken", token?.access);
      localStorage.setItem("refreshToken", token?.refresh);
      getAllData();
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || "Invalid OTP");
    }
  };

  const getAllData = () => {
    console.log("get all data");
    // dispatch(getCountriesData());
    dispatch(getProfileData());
    dispatch(getPermissions());
    // dispatch(getCurrencyTypes());
    // dispatch(getDepositTypes());
    // dispatch(getPurposeTypes());
    // dispatch(getRemittanceStatuses());
    // dispatch(getRedeemStatuses());
    // dispatch(getKycDocTypes());
    // dispatch(getRemittanceTypes());
    // dispatch(getWallets());
    // dispatch(getCoins());
    history.replace("/");
  };

  return (
    <main className="signInPage background">
      <section className="signInheader">
        <img src={ipusalogo} alt="IPUSA" />
      </section>
      <section className="signInContent">
        <div className="signInBox">
          <div className="signInBoxHeader">
            <p className="dialog-title fw-bolder">Sign in</p>
            <p>Welcome to IPUSA</p>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                formik.handleSubmit();
              }
            }}
          >
            <div>
              <Stack spacing={1} direction={"column"}>
                <TextFieldComponent
                  formik={formik}
                  name={"email"}
                  type={"email"}
                  label={"Email"}
                />
                <TextFieldPassword
                  formik={formik}
                  name={"password"}
                  label={"Password"}
                />
              </Stack>
            </div>
            <div
              className="text-primary mb-2 mt-1 fw-bolder"
              style={{ textAlign: "right" }}
            >
              <Link to={Routes.ForgotPassword.path}>Forgot password?</Link>
            </div>
            <div className="submitBtnContainer">
              {/* {process.env.REACT_APP_STB_ENV === 'TEST' && (
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={onCaptchaClick}
              />
            )} */}
              <Button
                disabled={!formik.isValid || !isCaptchaValid}
                className={`btn action-button primary_color mt-0 text-white grow-1 w-100 mt-4 ${
                  !formik.isValid || !isCaptchaValid ? "disabled" : null
                }`}
                loading={loading}
                // type="submit"
                onClick={formik.handleSubmit}
                id={"login-button"}
              >
                Sign In
              </Button>
            </div>
            <OtpDialog
              title={"Enter OTP"}
              open={openOtpModal}
              onClose={() => setOpenOtpModal(false)}
              onSubmit={onOtpSubmit}
              loading={loading}
            />
          </form>
        </div>
      </section>
    </main>
  );
};

export default Signin;
