import React, { useCallback, useState } from "react";
import {
  Autocomplete,
  Box,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button } from "rsuite";

import {
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { Iconify, TextFieldComponent, TextFieldSelect } from "../../components";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { merchantApi } from "../../redux/api/apiConfig";
import { FEE, INTERNAL_TRANSFER } from "../../ApiUrl";
import SearchNotFound from "../SearchNotFound";
import { accountTypes } from "../../utils/common";
import { UploadDocument } from "../uploadDocumentModal";
import { AddNewCard } from "../addNewCard";

const validationSchema = yup.object({
  from: yup.object().required("From is required").nullable(),
  to: yup.object().required("To is required").nullable(),
  amount: yup
    .number("Enter amount")
    .min(0, "Please enter valid amount")
    .required("Amount is required!"),
});

const TransferActionModal = ({ onClose, isOpen, selectedAction }) => {
  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={"xs"}
        onClose={handleCloseModal}
        scroll={"body"}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>Transfer Actions</DialogTitle>
        <DialogContent
          sx={{ paddingTop: "5px !important", paddingBottom: "0 !important" }}
        >
          <Stack spacing={2} direction={"column"} alignItems={"center"} mb={1}>
            {selectedAction &&
              selectedAction?.map((item, i) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                    width: "100%",
                  }}
                  key={i}
                >
                  {" "}
                  <Iconify
                    icon="simple-line-icons:action-redo"
                    width={18}
                    sx={{ flex: "0 0 18px" }}
                  />
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                    {item}
                  </Typography>
                </Box>
              ))}
          </Stack>

          <DialogActions sx={{ justifyContent: "flex-end" }}>
            <Button
              className="btn white-btn action-button "
              onClick={onClose}
              appearance="subtle"
            >
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TransferActionModal;
