import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Badge,
  Image as RImage,
  Button,
  Accordion,
  Navbar,
} from "@themesberg/react-bootstrap";
import Image from "./image";
import { Divider, IconButton, Stack, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { CgHome } from "react-icons/cg";
import { connect, useDispatch, useSelector } from "react-redux";

// import stblogo from "../assets/img/icons/InvestvertexWhite.png";
import ipusalogo from "../assets/img/ip_transparent_logo.png";
import HomeIcon from "../assets/img/sideicon/home.svg";
import AdminIcon from "../assets/img/sideicon/adminstration.svg";
import TransIcon from "../assets/img/sideicon/transaction.svg";
import ProfileIcon from "../assets/img/sideicon/profile.svg";
import liquidityIcon from "../assets/img/sideicon/liquidity.svg";
import ExchangeIcon from "../assets/img/sideicon/exchange.svg";
import BankIcon from "../assets/img/sideicon/bank.svg";
import LevelIcon from "../assets/img/sideicon/level.svg";
import CurrencyIcon from "../assets/img/sideicon/currency.svg";
import InvoiceIcon from "../assets/img/sideicon/invoice.svg";
import ClientsIcon from "../assets/img/sideicon/clients.svg";
import SendersIcon from "../assets/img/sideicon/senders.svg";
import ConfigIcon from "../assets/img/sideicon/configuration.svg";
import FeesIcon from "../assets/img/sideicon/fees.svg";
import ReceiversIcon from "../assets/img/sideicon/receivers.svg";
import SupportIcon from "../assets/img/sideicon/support.svg";
import BusinessIcon from "../assets/img/sideicon/business.svg";
import IndividualIcon from "../assets/img/sideicon/individual.svg";
import KycIcon from "../assets/img/sideicon/kyc.svg";
import supportChatIcon from "../assets/img/sideicon/supportChat.svg";
import OutwardIcon from "../assets/img/sideicon/outward.svg";
import InwardIcon from "../assets/img/sideicon/inward.svg";
import DepositIcon from "../assets/img/sideicon/deposit.svg";
import PortalIcon from "../assets/img/sideicon/portal.svg";
import TransactionsIcon from "../assets/img/sideicon/transactions.svg";
import PortalUsersIcon from "../assets/img/sideicon/portalUsers.svg";
import RedemptionIcon from "../assets/img/sideicon/availableRedemption.svg";
import FeesLogo from "../assets/img/sideicon/feeslogo.svg";
import { Routes } from "../routes";
import { getStatus } from "../redux/actions/kybActions";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { toggleSidebar, showSidebar } from "../redux/actions/config";
import Scrollbar from "./Scrollbar";
import { hasPermission } from "../utils/common";

const Sidebar = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { showSidebar: isShowSidebar } = useSelector(
    (state) => state.configReducer
  );

  const role = useSelector((state) => state.profileReducer?.user?.roles);
  const { permissionLoading, permission } = useSelector(
    (state) => state.profileReducer
  );

  const matches = useMediaQuery("(min-width:768px)");

  useEffect(() => {
    dispatch(showSidebar());
  }, [matches, dispatch]);

  const { pathname } = location;
  console.log(pathname);
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, image, children = null, type = "" } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between border-none box-shad-none align-items-center"
          >
            <span>
              {image ? (
                <RImage
                  src={image}
                  width={20}
                  height={20}
                  className="sidebar-icon svg-icon"
                />
              ) : null}
              <span className="sidebar-text light_color colr font-fam">
                {title}
              </span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level pt-0">
            <Nav
              style={{ marginLeft: type === "nested" ? "2rem" : "" }}
              className="flex-column bgchild  "
            >
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
      subTitle = "",
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start   align-items-center justify-content-between "
      : "";
    let navItemClassName;
    // if (link === '/') {
    navItemClassName = pathname === link ? "active" : "";
    // } else {
    //   navItemClassName = pathname.includes(link) ? 'active' : '';
    // }
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span className="d-flex">
            {icon ? (
              <span className="sidebar-icon">
                <CgHome size="1em" />{" "}
              </span>
            ) : null}
            {image ? (
              <RImage
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}
            <span className="d-flex row">
              <span className="sidebar-text light_color font-fam">{title}</span>
              <span className="light_color font-11">{subTitle}</span>
            </span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  let sidebarContent = (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <Scrollbar
          className={`collapse ${showClass} sidebar d-md-block primary_color text-white ${
            !isShowSidebar ? "sidebar-hide" : ""
          }`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center"></div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0 ">
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "center",
                  mb: 5,
                }}
              >
                <Image
                  src={ipusalogo}
                  sx={{
                    width: "100px",
                  }}
                />
                {/* {matches && (
                  <IconButton
                    aria-label="toggle passphrase visibility"
                    onClick={() => {
                      dispatch(toggleSidebar());
                    }}
                    edge="end"
                    sx={{
                      color: "white",
                    }}
                  >
                    {<KeyboardArrowLeftIcon />}
                  </IconButton>
                )} */}
              </Stack>
              {hasPermission(role, permission?.dashboard?.permissions) && (
                <NavItem
                  title="Home"
                  link={Routes.DashboardOverview.path}
                  image={HomeIcon}
                />
              )}

              {hasPermission(role, permission?.reports?.permissions) && (
                <CollapsableNavItem
                  eventKey="reports/"
                  title="Reports"
                  image={TransIcon}
                >
                  {/* {!permission?.reports?.transactions?.permission?.visible[
                    role
                  ] && (
                    <NavItem
                      title="Total Payments Report"
                      image={TransactionsIcon}
                      link={Routes.TotalPaymentsReport.path}
                    />
                  )} */}

                  {hasPermission(
                    role,
                    permission?.reports?.totalPaymentsReport?.permissions
                  ) && (
                    <NavItem
                      title="Transaction Charges Report"
                      image={DepositIcon}
                      link={Routes.TransactionChargesReport.path}
                    />
                  )}
                  {hasPermission(
                    role,
                    permission?.reports?.lienReport?.permissions
                  ) && (
                    <NavItem
                      title="Lien Report"
                      image={InvoiceIcon}
                      link={Routes.LienReport.path}
                    />
                  )}
                  {/* {!permission?.reports?.inwardRemittanceReport?.permission
                    ?.visible[role] && (
                    <NavItem
                      title="Inward Remittance Report"
                      image={InvoiceIcon}
                      link={Routes.IncomingPaymentReport.path}
                    />
                  )} */}
                  {hasPermission(
                    role,
                    permission?.reports?.averageBalancesReport?.permissions
                  ) && (
                    <NavItem
                      title="Average Balances Report"
                      image={InvoiceIcon}
                      link={Routes.AverageBalancesReport.path}
                    />
                  )}
                  {/* {!permission?.reports?.pendingRedemptionReport?.permission
                    ?.visible[role] && (
                    <NavItem
                      title="Available Settlement Report"
                      image={InvoiceIcon}
                      link={Routes.AvailableRedemptionReport.path}
                    />
                  )} */}
                </CollapsableNavItem>
              )}
              {hasPermission(
                role,
                permission?.clients?.senders?.permissions
              ) && (
                <NavItem
                  title="Customers"
                  eventKey="customers"
                  link={Routes.Suppliers.path}
                  image={ClientsIcon}
                />
              )}
              {/* {permission?.clients?.senders?.userKyc?.permission?.visible[
                role
              ] && (
                <NavItem
                  title="KYB"
                  image={KycIcon}
                  link={Routes.UserKyc.path}
                />
              )} */}
              {/* {!permission?.clients?.permission?.visible[role] && (
                <CollapsableNavItem
                  eventKey="clients/"
                  title="Clients"
                  image={ClientsIcon}
                >
                  <>
                    {!permission?.clients?.senders?.permission?.visible[
                      role
                    ] && (
                      <CollapsableNavItem
                        eventKey="clients/senders/"
                        title="Senders"
                        image={SendersIcon}
                        type="nested"
                      >
                        <>
                          {!permission?.clients?.senders?.individual?.permission
                            ?.visible[role] && (
                            <NavItem
                              title="Individual"
                              image={IndividualIcon}
                              link={Routes.SenderIndividualClients.path}
                            />
                          )}
                          {!permission?.clients?.senders?.business?.permission
                            ?.visible[role] && (
                            <NavItem
                              title="Business"
                              image={BusinessIcon}
                              link={Routes.SenderBusinessClients.path}
                            />
                          )}
                          {!permission?.clients?.senders?.deposits?.permission
                            ?.visible[role] && (
                            <NavItem
                              title="Deposits"
                              image={DepositIcon}
                              link={Routes.Deposits.path}
                            />
                          )}
                          {!permission?.clients?.senders?.userKyc?.permission
                            ?.visible[role] && (
                            <NavItem
                              title="KYC"
                              image={KycIcon}
                              link={Routes.UserKyc.path}
                            />
                          )}
                        </>
                      </CollapsableNavItem>
                    )}
                    {!permission?.clients?.receivers?.permission?.visible[
                      role
                    ] && (
                      <CollapsableNavItem
                        eventKey="clients/receivers/"
                        title="Receivers"
                        image={ReceiversIcon}
                        type="nested"
                      >
                        <>
                          {!permission?.clients?.receivers?.individual
                            ?.permission?.visible[role] && (
                            <NavItem
                              title="Individual"
                              image={IndividualIcon}
                              link={Routes.ReceiverIndividualClients.path}
                            />
                          )}
                          {!permission?.clients?.receivers?.business?.permission
                            ?.visible[role] && (
                            <NavItem
                              title="Business"
                              image={BusinessIcon}
                              link={Routes.ReceiverBusinessClients.path}
                            />
                          )}
                        </>
                      </CollapsableNavItem>
                    )}
                  </>
                </CollapsableNavItem>
              )} */}

              {/* <NavItem
                title="User Chat"
                image={KycIcon}
                link={Routes.UserChatGeneral.path}
              /> */}
              {hasPermission(role, permission?.payments?.permissions) && (
                <CollapsableNavItem
                  eventKey="payment/"
                  title="Payments"
                  image={InvoiceIcon}
                >
                  <>
                    {hasPermission(
                      role,
                      permission?.payments?.incoming?.permissions
                    ) && (
                      <NavItem
                        title="Incoming Transfer"
                        image={InvoiceIcon}
                        link={Routes.IncomingTransfer.path}
                      />
                    )}
                    {hasPermission(
                      role,
                      permission?.payments?.internalTransfer?.permissions
                    ) && (
                      <NavItem
                        title="Internal Transfer"
                        image={ExchangeIcon}
                        link={Routes.InternalTransfer.path}
                      />
                    )}
                  </>
                </CollapsableNavItem>
              )}
              {hasPermission(
                role,
                permission?.reports?.totalPaymentsReport?.permissions
              ) && (
                <NavItem
                  title="Contracts"
                  image={ExchangeIcon}
                  link={Routes.ProgrammedLiens.path}
                />
              )}

              {hasPermission(role, permission?.configurations?.permissions) && (
                <CollapsableNavItem
                  eventKey="configurations/"
                  title="Configurations"
                  image={ConfigIcon}
                >
                  <>
                    {hasPermission(
                      role,
                      permission?.configurations?.fees?.permissions
                    ) && (
                      <CollapsableNavItem
                        eventKey="configurations/fees/"
                        title="Fees"
                        image={FeesIcon}
                        type="nested"
                      >
                        <>
                          <CollapsableNavItem
                            eventKey="configurations/fees/transaction/"
                            title="Transaction Fees"
                            image={FeesIcon}
                            type="nested"
                          >
                            <>
                              {hasPermission(
                                role,
                                permission?.configurations?.fees?.permissions
                              ) && (
                                <NavItem
                                  title="Incoming Payments Fees"
                                  image={FeesLogo}
                                  link={Routes.IncomingPaymentFees.path}
                                />
                              )}
                              {hasPermission(
                                role,
                                permission?.configurations?.fees?.permissions
                              ) && (
                                <NavItem
                                  title="Outgoing Payments Fees"
                                  image={FeesLogo}
                                  link={Routes.OutgoingPaymentFees.path}
                                />
                              )}
                              {hasPermission(
                                role,
                                permission?.configurations?.fees?.permissions
                              ) && (
                                <NavItem
                                  title="Internal Transfer Fees"
                                  image={FeesLogo}
                                  link={Routes.InternalTransferFees.path}
                                />
                              )}
                            </>
                          </CollapsableNavItem>

                          {hasPermission(
                            role,
                            permission?.configurations?.fees?.permissions
                          ) && (
                            <NavItem
                              title="Monthly Fees"
                              image={FeesLogo}
                              link={Routes.MonthlyFees.path}
                            />
                          )}
                          {hasPermission(
                            role,
                            permission?.configurations?.fees?.permissions
                          ) && (
                            <NavItem
                              title="Markup Fees"
                              image={FeesLogo}
                              link={Routes.MarkupFees.path}
                            />
                          )}
                          {hasPermission(
                            role,
                            permission?.configurations?.fees?.permissions
                          ) && (
                            <NavItem
                              title="Other Fees"
                              image={FeesLogo}
                              link={Routes.OtherFees.path}
                            />
                          )}
                        </>
                      </CollapsableNavItem>
                    )}
                    {hasPermission(
                      role,
                      permission?.configurations?.financialAccounts?.permissions
                    ) && (
                      <NavItem
                        title="Financial Accounts"
                        image={BankIcon}
                        link={Routes.FinancialAccounts.path}
                      />
                    )}
                    {/* {!permission?.configurations?.exchangeOffers?.permission
                      ?.visible[role] && (
                      <NavItem
                        title="Exchange Offers"
                        image={ExchangeIcon}
                        link={Routes.ExchangeOffers.path}
                      />
                    )}
                   
                    {!permission?.configurations?.kycLevels?.permission?.visible[
                      role
                    ] && (
                      <NavItem
                        title="KYC Levels"
                        image={LevelIcon}
                        link={Routes.KycLevels.path}
                      />
                    )} */}
                  </>
                </CollapsableNavItem>
              )}
              {/* {!permission?.accounts?.account?.permission?.visible[
                      role
                    ] && (
                      <NavItem
                        title="Sub Accounts"
                        image={ProfileIcon}
                        link={Routes.SubAccounts.path}
                      />
                    )} */}
              {/* {!permission?.accounts?.permission?.visible[role] && (
                <CollapsableNavItem
                  eventKey="accounts/"
                  title="Accounts"
                  image={InvoiceIcon}
                >
                  <>
                    
                    {!permission?.accounts?.liquidity?.permission?.visible[
                      role
                    ] && (
                      <NavItem
                        title="Liquidity Requests"
                        image={liquidityIcon}
                        link={Routes.Liquidity.path}
                      />
                    )}
                    {!permission?.accounts?.currencies?.permission?.visible[
                      role
                    ] && (
                      <NavItem
                        title="Currencies"
                        image={CurrencyIcon}
                        link={Routes.Currencies.path}
                      />
                    )}
                  </>
                </CollapsableNavItem>
              )} */}

              {/* {!permission?.transactions?.permission?.visible[role] && (
                <NavItem
                  title="Transactions"
                  link={Routes.ReportTransactions.path}
                  image={TransactionsIcon}
                />
              )} */}

              <Divider />
              {hasPermission(role, permission?.support?.permissions) && (
                <CollapsableNavItem
                  eventKey="support/"
                  title="Support"
                  image={SupportIcon}
                >
                  {/* {!permission?.support?.platformSupport?.permission?.visible[
                    role
                  ] && (
                    <NavItem
                      title="Platform Support"
                      image={supportChatIcon}
                      link={Routes.Support.path}
                      subTitle={"Your queries with STB team"}
                    />
                  )} */}
                  {hasPermission(
                    role,
                    permission?.support?.customerSupport?.permissions
                  ) && (
                    <NavItem
                      title="Customer Support"
                      image={supportChatIcon}
                      link={Routes.UserChatGeneral.path}
                      subTitle={"Customers' queries with your team"}
                    />
                  )}
                </CollapsableNavItem>
              )}
              {/* {permission?.profile?.permission?.visible[role] && (
                <NavItem
                  title="Profile"
                  image={ProfileIcon}
                  link={Routes.Profile.path}
                />
              )} */}
            </Nav>
          </div>
        </Scrollbar>
      </CSSTransition>
    </>
  );
  return sidebarContent;
};

function mapStateToProps({ kybReducer, authReducer }) {
  const { status } = kybReducer;
  const { userRole, isSingleWallet } = authReducer;
  return {
    data: status.data,
    userRole,
    isSingleWallet,
  };
}

const actionCreators = {
  getStatus,
};

export default connect(mapStateToProps, actionCreators)(Sidebar);
