import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Stack, Typography, LinearProgress } from "@mui/material";
import { formatWithoutTimezone, rowsPerPageOptions } from "../../../../utils/common";

import { DataGrid } from "@mui/x-data-grid";
import { tableCellStyle } from "../../../../utils/common";
import { Label } from "../../../../components";
import { Routes } from "../../../../routes";
import { fCurrency } from "../../../../utils/formatNumber";
import { format } from "date-fns";

export default function TransactionStatementTable({
  translist,
  fetchTranslistLoading,
  filters,
  setFilters,
}) {
  const history = useHistory();

  const handleChangePage = (newPage) => {
    setFilters((prev) => ({
      ...prev,
      currentPage: newPage,
    }));
  };

  const handleChangeRowsPerPage = (newSize) => {
    setFilters((prev) => ({
      ...prev,
      currentPage: 0,
      pageSize: newSize,
    }));
  };

  const createDataRow = (transaction) => {
    // const type = transaction.type;
    return {
      id: transaction?.id,
      date: formatWithoutTimezone(transaction?.created_date, "dd MMM yyyy"),
      type: transaction?.type,
      debtor: transaction?.debtor?.name || "--",
      creditor: transaction?.creditor?.name || "--",
      maker: transaction?.maker?.name || "--",
      checker: transaction?.checker?.name || "--",
      amount: fCurrency(transaction?.amount),
      balance_post_txn: fCurrency(transaction?.balance_post_txn),
    };
  };

  const rows =
    translist?.results?.length > 0
      ? translist.results.map((transaction) => createDataRow(transaction))
      : [];

  const columns = [
    {
      field: "id",
      headerName: "Txn ID",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack
            sx={{ width: "100%", height: "100%", cursor: "pointer" }}
            justifyContent={"center"}
            alignItems={"center"}
            onClick={() => {
              history.push({
                pathname: Routes.ProgrammedLiensDetailView.path,
                search: `?id=${params.formattedValue}`,
              });
            }}
          >
            <Typography
              variant="body2"
              style={{ ...tableCellStyle, fontWeight: 600 }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: "ellipsis",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Stack direction={"row"} sx={{ minWidth: 0, alignItems: "center" }}>
            {/* <Avatar
              alt={params.formattedValue}
              sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}
            >
              {createAvatar(params.formattedValue).name}
            </Avatar> */}
            <Stack>
              <Typography
                variant="body2"
                sx={{
                  color: "black",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: "500",
                }}
              >
                {params?.formattedValue}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: "ellipsis",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Stack direction={"row"} sx={{ minWidth: 0, alignItems: "center" }}>
            <Stack>
              <Typography
                variant="body2"
                sx={{
                  color: "black",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: "500",
                }}
              >
                {params?.formattedValue || "--"}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "balance_post_txn",
      headerName: "Balance Post Txn",
      sortable: false,
      flex: 1.5,
      minWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
            <Typography
              variant="body2"
              sx={{
                color: "black",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "500",
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "debtor",
      headerName: "Debtor",
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: "ellipsis",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Stack direction={"row"} sx={{ minWidth: 0, alignItems: "center" }}>
            {/* <Avatar
                alt={params.formattedValue}
                sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}
              >
                {createAvatar(params.formattedValue).name}
              </Avatar> */}
            <Stack>
              <Typography
                variant="body2"
                sx={{
                  color: "black",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: "500",
                }}
              >
                {params?.formattedValue}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "creditor",
      headerName: "Creditor",
      flex: 1,
      minWidth: 250,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {`${params.formattedValue}`}
          </Typography>
        );
      },
    },

    {
      field: "maker",
      headerName: "Maker",
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "checker",
      headerName: "Checker",
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
          borderRadius: "8px",
          // height: 825,
          width: "100%",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: "#667085",
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
            },
          },
          "& .header": {
            backgroundColor: "#F4F4F4",
            "& div": {
              "& div": {
                "& div": {
                  "& div": {
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: "0 0 8px 8px",
            // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
            width: "100%",
            cursor: "pointer",
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          // onRowClick={(row) => {
          //   history.push({
          //     pathname: Routes.ProgrammedLiensDetailView.path,
          //     search: `?id=${row.id}`,
          //   });
          // }}
          rows={rows}
          columns={columns}
          rowHeight={80}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode="server"
          rowsPerPageOptions={rowsPerPageOptions}
          page={filters.currentPage}
          rowCount={translist.count}
          pageSize={filters.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
        />
      </Box>
    </>
  );
}
