import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AddNewCard } from "../../../components/addNewCard";
import ContractDocumentCard from "../../../components/documentCard/ContractDocumentCard";
import UploadContractDocument from "../../../components/uploadDocumentModal/UploadContractDocument";
import { merchantApi } from "../../../redux/api/apiConfig";
import * as API from "../../../ApiUrl";
import { toast } from "react-toastify";
import useDeleteModal from "../../../hooks/useDeleteModal";
import { DeleteModal } from "../../../components/deleteModal";

const ContractDocument = ({ contractDocument, contractId, getContractDetail }) => {
  const [selectedDocument, setSelectedDocument] = useState([]);
  const [showDocumentUpload, setShowDocumentUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteDocId, setDeleteDocId] = useState(null);
  const { showDeleteModal, toggleShowDeleteModal } = useDeleteModal();
  useEffect(() => {
    if (contractDocument) {
      setSelectedDocument(
        contractDocument?.map((val) => ({
          id: val?.id,
          name: val?.document_name,
          document: val?.attachment_url,
        }))
      );
    }
  }, [contractDocument]);

  const toggleDocumentUpload = () => {
    setShowDocumentUpload((prevState) => !prevState);
  };

  async function onDocumentDelteHandler(id) {
    setIsLoading(true);
    merchantApi
      .delete(`${API.CONTRACTS}${contractId}/documents/${id}/`)
      .then((response) => {
        setIsLoading(false);
        toast.success("Document Deleted Successfully!");
        setSelectedDocument((prev) => prev?.filter((data) => data?.id !== id));
        toggleShowDeleteModal();
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.data?.data?.message || "Error Deleting Document");
      });
  }

  async function addNewDocumentHandler(val) {
    setIsLoading(true);
    merchantApi
      .post(`${API.CONTRACTS}${contractId}/documents/`, {
        document_name: val?.name,
        attachment: val?.id,
      })
      .then((response) => {
        setIsLoading(false);
        toast.success("Document Uploaded Successfully!");
        toggleDocumentUpload();
        getContractDetail();
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.data?.data?.message || "Error Uploading Document");
      });
  }

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gridGap: "1rem",
        width: "100%",
        mt: 6,
      }}
    >
      {selectedDocument?.length === 0 ? (
        <AddNewCard onClick={toggleDocumentUpload} />
      ) : (
        <>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, 250px)",
              gridGap: "20px",
              width: "100%",
            }}
          >
            {selectedDocument?.map((document, i) => (
              // <Stack onClick={() => handleOpenLightbox(i)} key={i}>
              <Stack key={i}>
                <ContractDocumentCard
                  // isSelected={selectedSenderDocuments.includes(document.id)}
                  documents={document}
                  docName={document?.name}
                  // onDocumentSelect={onDocumentSelect}
                  onDocumentDelete={(id) => {
                    toggleShowDeleteModal();
                    setDeleteDocId(id);
                  }}
                  canDelete={true}
                />
              </Stack>
            ))}
            <AddNewCard onClick={toggleDocumentUpload} />
          </Box>
        </>
      )}
      {showDocumentUpload && (
        <UploadContractDocument
          isOpen={showDocumentUpload}
          onClose={toggleDocumentUpload}
          isLoading={isLoading}
          onDocUpload={(val) => {
            addNewDocumentHandler(val);
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          onClose={toggleShowDeleteModal}
          isOpen={showDeleteModal}
          onSubmit={() => onDocumentDelteHandler(deleteDocId)}
          text={"Contract Document"}
          loading={isLoading}
        />
      )}
    </Box>
  );
};

export default ContractDocument;
