import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button } from "rsuite";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { TextFieldComponent } from "../../components";
import { toast } from "react-toastify";
import { merchantApi } from "../../redux/api/apiConfig";
import { BROADCAST_PAYMENT, CONTRACTS } from "../../ApiUrl";

const validationSchema = yup.object({
  message: yup.string().required("Message is required!"),
});

const BroadcastUnmappedModal = ({ onClose, isOpen, onSuccess, transferId }) => {
  const [loading, setLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState({
    message: "",
  });
  const theme = useTheme();

  const addClickHandler = async (values) => {
    const reqData = {
      ...values,
      against_transfer: transferId,
    };
    try {
      setLoading(true);
      const response = await merchantApi.post(BROADCAST_PAYMENT, reqData);
      toast.success("Payment Broadcasted!");
      setLoading(false);
      setTimeout(() => {
        formik.resetForm();
        onSuccess();
        onClose();
      }, 1000);
    } catch (err) {
      setLoading(false);
      toast.error(
        err?.response?.data?.message || "Error Broadcasting Payment!"
      );
    }
  };

  const formik = useFormik({
    initialValues: defaultValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: addClickHandler,
  });

  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={"sm"}
        onClose={handleCloseModal}
        scroll={"body"}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontSize: "24px",
            color: theme.palette.common.accentColor,
          }}
        >
          Create Broadcast For Unmapped Payment
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent
            sx={{
              paddingTop: "0px !important",
              paddingBottom: "0 !important",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr",
                gap: "20px",
              }}
            >
              <TextFieldComponent
                formik={formik}
                name={"message"}
                type={"text"}
                label={"Message*"}
                multiline
                rows={4}
              />
            </Box>

            <DialogActions sx={{ justifyContent: "space-between" }}>
              <Button
                className="btn white-btn action-button "
                onClick={() => {
                  formik.resetForm();
                  //   setSelectedDocument([]);
                  onClose();
                }}
                appearance="subtle"
              >
                Cancel
              </Button>
              <Button
                className={`btn action-button primary_color text-white`}
                appearance="primary"
                type={"submit"}
                disabled={!formik.isValid}
                loading={loading}
              >
                Broadcast Payment
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
};

export default BroadcastUnmappedModal;
