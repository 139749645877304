import React, { useState, useEffect, useCallback } from "react";
import "rsuite/dist/styles/rsuite-default.css";
import { Box } from "@mui/material";
import usePageTitle from "../../../../hooks/usePageTitle";
import KybKycStatusHistoryTable from "./kybKycStatusHistoryTable";

function KycKybStatusHistory({ client, getClient }) {
  const [kybData, setKybData] = useState(client?.kyb_status);
  const [kycData, setKycData] = useState(
    client?.shareholders
  );

  return (
    <>
      <Box sx={{ padding: "0 24px 24px" }}>
        <KybKycStatusHistoryTable kybData={kybData} kycData={kycData} />
      </Box>
    </>
  );
}

export default KycKybStatusHistory;
