import { Box, Stack, Tab, Tabs, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "rsuite";
import InvoiceReceiptsAuditLogTable from "./invoiceReceiptsAuditLogTable";
import TransferActionModal from "../../../../components/transferActionModal/transferActionModal";
import { capitalize } from "lodash";
import TransactionAuditLogTable from "./transactionAuditLog";
import { merchantApi } from "../../../../redux/api/apiConfig";
import {
  CONTRACT_PAYMENT,
  CUSTOMER_PAYMENT,
  INCOMING_TRANSFER,
} from "../../../../ApiUrl";
import { toast } from "react-toastify";
import GroupedSelect from "../../../../components/GroupedSelect";
import { Iconify } from "../../../../components";

const InvoiceReceiptsAuditLog = ({ details }) => {
  const [currentTab, setCurrentTab] = useState("transfer audit log");
  const [viewAction, setViewAction] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [transactionData, setTransactionData] = useState(null);
  const [transferAuditLog, setTransferAuditLog] = useState({
    count: 0,
    results: [],
  });
  const [transactionAuditLog, setTransactionAuditLog] = useState({
    count: 0,
    results: [],
  });
  const [selectedPayment, setSelectedPayment] = useState(null);
  const theme = useTheme();

  const PREVIEW_TABS = [
    {
      value: "transfer audit log",
      icon: (
        <Iconify icon={"material-symbols:history"} width={20} height={20} />
      ),
      component: (
        <InvoiceReceiptsAuditLogTable
          translist={transferAuditLog}
          onViewAction={(val) => {
            setViewAction(true);
            setSelectedAction(val);
          }}
        />
      ),
    },
    {
      value: "transaction audit log",
      icon: (
        <Iconify icon={"material-symbols:history"} width={20} height={20} />
      ),
      component: (
        <TransactionAuditLogTable
          translist={transactionAuditLog}
          onViewAction={(val) => {
            setViewAction(true);
            setSelectedAction(val);
          }}
        />
      ),
    },
  ];

  async function getTransferTransactionList() {
    merchantApi
      .get(`${INCOMING_TRANSFER}${details?.id}/transactions/`)
      .then((response) => {
        if (response?.data) {
          setTransactionData(response?.data);
        }
      })
      .catch((error) => {
        toast.error(
          error?.data?.data?.message || "Error getting transaction list"
        );
      });
  }

  async function getTransactionAuditLog(id, type) {
    const url =
      type === "contract_payment" ? CONTRACT_PAYMENT : CUSTOMER_PAYMENT;
    merchantApi
      .get(`${url}${id}/history/`)
      .then((response) => {
        if (response?.data) {
          setTransactionAuditLog({
            count: response?.data?.data?.history?.length || 0,
            results: response?.data?.data?.history,
          });
        }
      })
      .catch((error) => {
        toast.error(
          error?.data?.data?.message || "Error getting Transaction Audit Log"
        );
      });
  }

  async function getTransferAuditLog() {
    merchantApi
      .get(`${INCOMING_TRANSFER}${details?.id}/history/`)
      .then((response) => {
        if (response?.data) {
          setTransferAuditLog({
            count: response?.data?.data?.history?.length || 0,
            results: response?.data?.data?.history,
          });
        }
      })
      .catch((error) => {
        toast.error(
          error?.data?.data?.message || "Error getting Transfer Audit Logs"
        );
      });
  }

  useEffect(() => {
    if (details) {
      getTransferTransactionList();
      getTransferAuditLog();
    }
  }, [details]);

  useEffect(() => {
    if (transactionData) {
      setSelectedPayment(
        JSON.stringify({
          id: transactionData?.contract_payment[0]?.id,
          type: "contract_payment",
        })
      );
    }
  }, [transactionData]);

  useEffect(() => {
    if (selectedPayment) {
      const data = JSON.parse(selectedPayment);
      getTransactionAuditLog(data?.id, data?.type);
    }
  }, [selectedPayment]);

  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ mt: 3 }}>
        <Stack
          justifyContent={"space-between"}
          alignItems={{ md: "center", xs: "start" }}
          direction={{ md: "row", xs: "column" }}
          spacing={2}
          sx={{
            background: theme.palette.common.lightAccentColor,
            mb: 2,
            p: 1,
            borderRadius: "7px",
          }}
        >
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={(e, newValue) => setCurrentTab(newValue)}
            sx={{
              background: theme.palette.common.lightAccentColor,
              width: "100%",
              borderRadius: "7px",
              "& .MuiTab-labelIcon": {
                color: theme.palette.common.accentColor,
              },
            }}
          >
            {PREVIEW_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={capitalize(tab.value)}
                iconPosition="start"
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </Tabs>
          {!(currentTab === "transfer audit log") && (
            <GroupedSelect
              transactionsDetails={transactionData}
              setSelectedPayment={setSelectedPayment}
              selectedPayment={selectedPayment}
            />
          )}
        </Stack>
        {PREVIEW_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Box>
      {viewAction && (
        <TransferActionModal
          isOpen={viewAction}
          onClose={() => {
            setViewAction(false);
            setSelectedAction(null);
          }}
          selectedAction={selectedAction}
        />
      )}
    </Box>
  );
};

export default InvoiceReceiptsAuditLog;
