import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { merchantApi } from "../../../../redux/api/apiConfig";
import { CUSTOMER_TRANSACTION, INCOMING_TRANSFER } from "../../../../ApiUrl";
import { toast } from "react-toastify";
import CustomerAllTransactionsTable from "./customerAllTransactionsTable";

const CustomerAllTransactions = ({ client }) => {
  const [transactionData, setTransactionData] = useState({});
  const [loading, seLoading] = useState(false);
  const [filters, setFilters] = useState({
    currentPage: 0,
    pageSize: 7,
  });
  async function getTransactionData(filters) {
    let params = {
      offset: filters?.currentPage * filters?.pageSize,
      limit: filters?.pageSize,
    };
    seLoading(true);
    merchantApi
      .get(`${CUSTOMER_TRANSACTION}${client?.id}/`, {
        params,
      })
      .then((response) => {
        if (response?.data) {
          seLoading(false);
          console.log(response?.data);
          setTransactionData(response?.data);
        }
      })
      .catch((error) => {
        seLoading(false);
        toast.error(
          error?.response?.data?.message || "Error getting transaction list"
        );
      });
  }

  useEffect(() => {
    getTransactionData(filters);
  }, [filters]);
  return (
    <Box sx={{ mt: 2 }}>
      <CustomerAllTransactionsTable
        translist={transactionData}
        filters={filters}
        setFilters={setFilters}
        fetchTranslistLoading={loading}
      />
    </Box>
  );
};

export default CustomerAllTransactions;
