import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Highlight, HighlightSkeleton } from "../../../reports/components";
import InfoCard from "../../../../components/infoCard/InfoCard";
import { Iconify, PreviewContainer, PreviewItem } from "../../../../components";
import { EditMonthlyFeesModal } from "../../../configurations/fees/feesComponents";
import { capitalize } from "lodash";
import { format } from "date-fns";
import SupplierDocumentDetails from "../../supplierDocumentDetail";
import KycKybStatusUpdate from "../../kyckybStatusUpateForm";
import { useDispatch, useSelector } from "react-redux";
import { getCountriesData } from "../../../../redux/actions/config";
import _ from "lodash";

const BusinessDetails = ({ client, getClients }) => {
  const [highlightLoading, setHighlightLoading] = useState(false);
  const [adminShareholder, setAdminShareholder] = useState(
    client?.shareholders?.find(
      (item) => item?.email === client?.adminshareholder?.email
    )
  );
  const theme = useTheme();
  const { countries } = useSelector((store) => store?.configReducer);
  const [showEditMonthlyFeesModal, setShowEditMonthlyFeesModal] =
    useState(false);
  const [highlights, setHighlights] = useState([
    { heading: "Total Pending Liens", data: 23, meta: { color: "green" } },
    { heading: "Total Settled Liens", data: 23, meta: { color: "brown" } },
    {
      heading: "Total Borrowings Lifetime",
      data: 23,
      meta: { color: "brown" },
    },
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCountriesData());
  }, []);

  const toggleEditMonthlyFeesModal = () => {
    setShowEditMonthlyFeesModal((prev) => !prev);
  };

  let jurisdiction = "";
  if (!_.isEmpty(client?.jurisdictions) && !_.isEmpty(countries)) {
    const jurisdictionCountries = client?.jurisdictions
      .map(
        (jurisdictionId) =>
          countries?.find((country) => country.id === jurisdictionId)?.name
      )
      .reduce((prev, curr) => {
        return prev + ", " + curr;
      });

    jurisdiction = jurisdictionCountries;
  }

  const businessDetails = (
    <>
      <PreviewItem title={"Business Name"} content={client?.business_name} />
      <PreviewItem title={"Full Name"} content={client?.primary_contact_name} />
      <PreviewItem title={"Country"} content={client?.country?.name} />
      <PreviewItem title={"Province"} content={client?.province?.name} />
      <PreviewItem title={"City"} content={client?.city?.name} />
      <PreviewItem
        title={"Designation"}
        content={client?.adminshareholder?.designation}
      />
      <PreviewItem
        title={"Business Category"}
        content={client?.category?.parent_category?.name}
      />
      <PreviewItem
        title={"Business Sub Category"}
        content={client?.category?.name}
      />
      <PreviewItem title={"Occupation Type"} content={client?.occupation} />
      <PreviewItem title={"Registration Number"} content={client?.company_id} />
      <PreviewItem title={"Postal Code"} content={client?.postal_code} />
      <PreviewItem
        title={"Date of Incorporation"}
        content={client?.incorporation_date}
      />
      <PreviewItem title={"Website"} content={client?.company_url} />
      <PreviewItem title={"KYB Status"} content={client?.kyb_status?.display} />

      <PreviewItem
        title={"Average Transaction Size"}
        content={`${client?.average_transaction_size} USD`}
      />
      <PreviewItem title={"Volume"} content={`${client?.volume} USD/month`} />
      <PreviewItem
        title={"frequency"}
        content={`${client?.frequency} USD/month`}
      />
      <PreviewItem title={"Tax ID"} content={client?.tax_id} />

      <PreviewItem title={"Jurisdiction"} content={jurisdiction} />
      <PreviewItem title={"Address"} content={client?.address} />
      <PreviewItem
        title={"Does this company have any parents or subsidiaries?"}
        content={client?.has_parent_or_subsidiary ? "Yes" : "No"}
      />
    </>
  );

  const personalDetails = (
    <>
      <PreviewItem
        title={"First Name"}
        content={adminShareholder?.first_name}
      />
      <PreviewItem title={"Last Name"} content={adminShareholder?.last_name} />
      <PreviewItem
        title={"Authorized representative's date of birth"}
        content={adminShareholder?.dob}
      />
      <PreviewItem
        title={"Primary Contact Number"}
        content={adminShareholder?.primary_phone_number}
      />
      <PreviewItem
        title={"Secondary Contact Number"}
        content={adminShareholder?.secondary_phone_number}
      />
      <PreviewItem
        title={"Are you a Key Controller of the Business?"}
        content={adminShareholder?.is_controller_or_ubo ? "Yes" : "No"}
      />
      <PreviewItem
        title={"Country"}
        content={adminShareholder?.country?.name}
      />
      <PreviewItem
        title={"Province/State"}
        content={adminShareholder?.province?.name}
      />
      <PreviewItem title={"City"} content={adminShareholder?.city?.name} />
      <PreviewItem
        title={"Postal Code"}
        content={adminShareholder?.postal_code}
      />
      <PreviewItem title={"Address"} content={adminShareholder?.address} />
      <PreviewItem
        title={"Source of Wealth"}
        content={adminShareholder?.source_of_wealth}
      />
      <PreviewItem title={"Tax ID"} content={adminShareholder?.tax_id} />
    </>
  );
  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          mb: 3,
          gap: 3,
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        {/* {highlightLoading
              ? [...Array(1)].map((a) => <HighlightSkeleton />)
              : highlights.map((highlight, i) => {
                  return (

                    <Highlight
                      key={highlight.heading}
                      title={highlight.heading}
                      subTitle={highlight.sub_heading}
                      // total={translist.count}
                      percent={50}
                      value={highlight.data}
                      icon={highlight?.meta?.icon}
                      color={highlight?.meta?.color}
                    />
                  );
                })} */}
        {/* <InfoCard
                    title={'Monthly Fee'}
                    number={'24 USD'}
                    icon="la:hand-holding-usd"
                    color={'info'}
                    action={<IconButton onClick={toggleEditMonthlyFeesModal} ><Iconify  icon='eva:edit-fill'/></IconButton>}
                    /> */}
      </Stack>

      <Accordion
        sx={{
          width: "100%",
          background: theme?.palette?.common?.lightAccentColor,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{ fontWeight: 500, color: theme?.palette?.common?.accentColor }}
          >
            Business Info
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PreviewContainer>{businessDetails}</PreviewContainer>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              mt: 1,
            }}
          >
            <KycKybStatusUpdate
              data={client?.kyb_status}
              id={client?.kyb_status?.id}
              isKyb
              getClients={getClients}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          width: "100%",
          background: theme?.palette?.common?.lightAccentColor,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{ fontWeight: 500, color: theme?.palette?.common?.accentColor }}
          >
            Personal Info
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PreviewContainer>{personalDetails}</PreviewContainer>
          {parseInt(adminShareholder?.ownership) === 0 && (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                mt: 1,
                mb: 2,
              }}
            >
              <KycKybStatusUpdate
                data={adminShareholder?.kyc}
                id={adminShareholder?.kyc?.id}
                getClients={getClients}
              />
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          width: "100%",
          background: theme?.palette?.common?.lightAccentColor,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{ fontWeight: 500, color: theme?.palette?.common?.accentColor }}
          >
            Shareholders Info
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {client?.shareholders
            ?.filter((item) => !(parseInt(item?.ownership) === 0))
            .map((item, i) => {
              return (
                <>
                  <PreviewContainer key={i} title="Shareholder Data">
                    <PreviewItem
                      title={
                        item?.shareholder_type === "company"
                          ? "Business Name"
                          : "First Name"
                      }
                      content={
                        item?.shareholder_type === "company"
                          ? `${item?.first_name} ${item?.last_name}`
                          : item?.first_name
                      }
                    />
                    <PreviewItem
                      title={
                        item?.shareholder_type === "company"
                          ? "Company Type"
                          : "Last Name"
                      }
                      content={item?.last_name}
                    />
                    <PreviewItem
                      title={
                        item?.shareholder_type === "company"
                          ? "Company Registration Date"
                          : "Date of Birth"
                      }
                      content={item?.dob}
                    />
                    <PreviewItem title={"Email"} content={item?.email} />
                    <PreviewItem
                      title={"Primary Phone Number"}
                      content={item?.primary_phone_number}
                    />
                    <PreviewItem
                      title={"Ownership"}
                      content={`${item?.ownership}%`}
                    />
                    <PreviewItem
                      title={"Source of Wealth"}
                      content={`${item?.source_of_wealth}`}
                    />
                    <PreviewItem
                      title={
                        item?.shareholder_type === "company"
                          ? "Country of Registration"
                          : "Citizenship"
                      }
                      content={item?.citizenship}
                    />
                    <PreviewItem title={"Address"} content={item?.address} />
                  </PreviewContainer>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      mt: 1,
                      mb: 2,
                    }}
                  >
                    <KycKybStatusUpdate
                      data={item?.kyc}
                      id={item?.kyc?.id}
                      getClients={getClients}
                    />
                  </Box>
                </>
              );
            })}
        </AccordionDetails>
      </Accordion>
      {showEditMonthlyFeesModal && (
        <EditMonthlyFeesModal
          onClose={toggleEditMonthlyFeesModal}
          isOpen={showEditMonthlyFeesModal}
          isEdit={true}
          slabToEdit={{}}
          onSubmit={() => {
            toggleEditMonthlyFeesModal();
          }}
        />
      )}
    </Box>
  );
};

export default BusinessDetails;
