import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Button } from "rsuite";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Paper,
} from "@mui/material";
import { formatWithoutTimezone } from "../../utils/common";
import Label from "../Label";

const KybKycHistoryStatusModal = ({
  onClose,
  isOpen,
  data,
  loading = false,
}) => {
  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  function createData(
    created_date,
    modified_date,
    previous_status,
    current_status,
    modified_user
  ) {
    return {
      created_date: formatWithoutTimezone(created_date, "dd MMM yyyy HH:mm a"),
      modified_date: formatWithoutTimezone(
        modified_date,
        "dd MMM yyyy HH:mm a"
      ),
      previous_status,
      current_status,
      modified_user,
    };
  }

  const rows = data?.map((obj) =>
    createData(
      obj?.created_date,
      obj?.modified_date,
      obj?.previous_status,
      obj?.current_status_display,
      obj?.current_status,
      obj?.modified_user?.name
    )
  );

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={"md"}
        onClose={handleCloseModal}
        scroll={"body"}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>KYB/KYC Status History</DialogTitle>
        <DialogContent sx={{ paddingBottom: "0 !important" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 250 }}>
              <TableHead sx={{ background: "#f4f4f4" }}>
                <TableRow>
                  <TableCell>Created Date</TableCell>
                  <TableCell align="left">Modified Date</TableCell>
                  <TableCell align="left">Previous Status</TableCell>
                  <TableCell align="left">Current Status</TableCell>
                  <TableCell align="left">Modified By</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">
                      {formatWithoutTimezone(
                        row?.created_date,
                        "dd MMM yyyy HH:mm a"
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {formatWithoutTimezone(
                        row?.modified_date,
                        "dd MMM yyyy HH:mm a"
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {row?.previous_status ? (
                        <Label
                          variant={"ghost"}
                          color={
                            row?.previous_status === "complete"
                              ? "complete"
                              : row?.previous_status === "not_done"
                              ? "rejected"
                              : row?.previous_status === "rejected"
                              ? "rejected"
                              : "application submitted"
                          }
                          sx={{
                            textTransform: "capitalize",
                            width: "fit-content",
                            marginRight: "10px",
                          }}
                        >
                          {row?.previous_status_display}
                        </Label>
                      ) : (
                        "--"
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <Label
                        variant={"ghost"}
                        color={
                          row?.current_status === "complete"
                            ? "complete"
                            : row?.current_status === "not_done"
                            ? "rejected"
                            : row?.current_status === "rejected"
                            ? "rejected"
                            : "application submitted"
                        }
                        sx={{
                          textTransform: "capitalize",
                          width: "fit-content",
                          marginRight: "10px",
                        }}
                      >
                        {row?.current_status_display}
                      </Label>
                    </TableCell>
                    <TableCell align="left">
                      {row?.modified_user?.name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Button
              className={`btn action-button primary_color text-white`}
              appearance="primary"
              type={"submit"}
              onClick={onClose}
              loading={loading}
            >
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default KybKycHistoryStatusModal;
