import React, { useState, useEffect, useCallback } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Iconify, Label } from "../../components";
import { capitalize, repeat } from "lodash";
import {
  Box,
  Tab,
  Tabs,
  Stack,
  Skeleton,
  styled,
  Typography,
  Tooltip,
  Card,
  Grid,
  Divider,
  useTheme,
} from "@mui/material";
import back from "../../assets/img/icons/back.svg";
import profile from "../../assets/img/team/vanilla-profile.jpg";
import Image from "../../components/image/Image";
import { toast } from "react-toastify";
import { merchantApi } from "../../redux/api/apiConfig";
import {
  CONTRACTS,
  INCOMING_TRANSFER,
  CONTRACTS_SUPPLIERS,
} from "../../ApiUrl";
import usePageTitle from "../../hooks/usePageTitle";
// import { BusinessDetails, Transactions } from "./components";
// import ProgrammedLiens from "./components/programmedLiens";
import { CustomAvatar } from "../../components/customAvatar";
import signinBg from "../../assets/img/signinBg.png";
import { formatWithoutTimezone, statusColors } from "../../utils/common";
import InvoiceReceiptInfo from "./components/invoiceReceiptInfo/invoiceReceiptInfo";
import InvoiceReceiptsTransactions from "./components/invoiceReceiptsTransactions/invoiceReceiptsTransactions";
import InvoiceReceiptsAuditLog from "./components/invoiceReceiptsAuditLog/invoiceReceiptsAuditLog";
import { fCurrency } from "../../utils/formatNumber";
import PaymentClaims from "./components/paymentClaims/paymentClaims";

const StyledRoot = styled("div")(({ theme }) => ({
  "&:before": {
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)",
    // backgroundColor: alpha('#000000', 1),
    top: 0,
    zIndex: 9,
    content: "''",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
}));

const StyledInfo = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: "absolute",
  marginTop: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    right: "auto",
    display: "flex",
    alignItems: "center",
    left: theme.spacing(3),
    bottom: theme.spacing(5),
  },
}));

const InvoiceReceiptsDetails = () => {
  const location = useLocation();
  const history = useHistory();
  // const params = useParams();
  const query = new URLSearchParams(location.search);

  const filters = Object.fromEntries(query);
  const { id, tab } = filters;
  const [currentTab, setCurrentTab] = useState(filters?.tab || "details");
  const [loading, setLoading] = useState(false);
  const [transferDetail, setTransferDetail] = useState(null);
  const [contractList, setContractList] = useState({});
  const [lenderList, setLenderList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const theme = useTheme();

  usePageTitle("Incoming Transfer Details");

  async function getTransferDetail() {
    try {
      setLoading(true);
      const response = await merchantApi.get(`${INCOMING_TRANSFER}${id}/`);
      console.log(response);
      setTransferDetail(response.data);
      setLoading(false);
    } catch (error) {
      toast.error(error?.data?.data?.message || "Error getting contract data");
      setLoading(false);
    }
  }

  async function getContractList() {
    merchantApi
      .get(`${CONTRACTS}`)
      .then((response) => {
        if (response.data) {
          setContractList(response?.data?.results);
        }
      })
      .catch((error) => {
        toast.error(
          error?.data?.data?.message || "Error getting contracts list"
        );
      });
  }

  async function getLenderList() {
    merchantApi
      .get(`${`${CONTRACTS_SUPPLIERS}?type=lender`}`)
      .then((response) => {
        if (response.data) {
          setLenderList(response.data);
        }
      })
      .catch((error) => {
        toast.error(error?.data?.data?.message || "Error getting lender list");
      });
  }

  async function getSupplierList() {
    merchantApi
      .get(`${`${CONTRACTS_SUPPLIERS}?type=supplier`}`)
      .then((response) => {
        if (response.data) {
          setSupplierList(response.data);
        }
      })
      .catch((error) => {
        toast.error(error?.data?.data?.message || "Error getting lender list");
      });
  }

  const PREVIEW_TABS = [
    {
      value: "details",
      icon: <Iconify icon={"ic:round-account-box"} width={20} height={20} />,
      component: (
        <InvoiceReceiptInfo
          details={transferDetail}
          supplierList={supplierList}
          getTransferDetail={getTransferDetail}
        />
      ),
    },
    {
      value: "transactions",
      icon: <Iconify icon={"ion:documents"} width={20} height={20} />,
      component: (
        <InvoiceReceiptsTransactions
          contractList={contractList}
          lenderList={lenderList}
          details={transferDetail}
        />
      ),
    },
    {
      value: "audit log",
      icon: (
        <Iconify icon={"material-symbols:history"} width={20} height={20} />
      ),
      component: <InvoiceReceiptsAuditLog details={transferDetail} />,
    },
    {
      value: "payment claims",
      icon: (
        <Iconify
          icon={"material-symbols:payments-outline-rounded"}
          width={20}
          height={20}
        />
      ),
      component: <PaymentClaims details={transferDetail} />,
    },
  ];

  const backClickHandler = () => {
    history.goBack();
  };

  useEffect(() => {
    getTransferDetail();
    getContractList();
    getLenderList();
    getSupplierList();
  }, []);

  return (
    <Box sx={{ padding: "24px " }}>
      {loading ? (
        <Skeleton variant="rounded" animation={"wave"} height={1500} />
      ) : (
        <>
          <Stack justifyContent={"space-between"} direction={"row"}>
            <span onClick={backClickHandler} className="back">
              <img src={back} alt="back" />
              <span>Back</span>
            </span>
          </Stack>

          <Grid container sx={{ my: 3 }}>
            <Grid item md={8}>
              <Box
                sx={{
                  p: 3,
                  borderRadius: "7px",
                  backgroundColor: theme.palette.common.accentColor,
                }}
                direction={"column"}
                gap={2}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#fff",
                    fontWeight: "600",
                    fontSize: "20px",
                    mb: 1,
                  }}
                >
                  Transfer Details
                </Typography>
                <Divider
                  orientation="horizontal"
                  flexItem
                  sx={{ borderStyle: "solid", borderColor: "#fff" }}
                />
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ color: "#fff", fontSize: "16px" }}
                  >
                    <b>Amount:</b>
                    <br />
                    {fCurrency(transferDetail?.amount)}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ color: "#fff", fontSize: "16px" }}
                  >
                    <b>Settled Amount:</b>
                    <br />
                    {fCurrency(transferDetail?.settled_amount)}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ color: "#fff", fontSize: "16px" }}
                  >
                    <b>Date Of Receipt:</b>
                    <br />
                    {transferDetail?.created_date
                      ? formatWithoutTimezone(
                          transferDetail?.created_date,
                          "dd MMM yyyy"
                        )
                      : "--"}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ color: "#fff", fontSize: "16px" }}
                  >
                    <b>Paid By:</b>
                    <br />
                    {transferDetail?.paid_by ? transferDetail?.paid_by : "--"}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ color: "#fff", fontSize: "16px" }}
                  >
                    <b>COBO:</b>
                    <br />
                    {transferDetail?.cobo?.name
                      ? transferDetail?.cobo?.name
                      : "--"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Stack
            justifyContent={"space-between"}
            alignItems={{ md: "center", xs: "start" }}
            direction={{ md: "row", xs: "column" }}
            spacing={2}
          >
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={(e, newValue) => setCurrentTab(newValue)}
              sx={{
                background: theme.palette.common.lightAccentColor,
                width: "100%",
                borderRadius: "7px",
                "& .MuiTab-labelIcon": {
                  color: theme.palette.common.accentColor,
                },
              }}
            >
              {PREVIEW_TABS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  label={capitalize(tab.value)}
                  iconPosition="start"
                  icon={tab.icon}
                  value={tab.value}
                />
              ))}
            </Tabs>
          </Stack>
          {PREVIEW_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </>
      )}
    </Box>
  );
};

export default InvoiceReceiptsDetails;
