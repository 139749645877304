import React from "react";
// @mui
import { alpha } from "@mui/material/styles";
import { Stack, Typography, Box, CircularProgress } from "@mui/material";
// utils
// import { fCurrency } from '../../../utils/formatNumber';
// components
import { Iconify } from "../../../../components";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../../../utils/common";
import _ from "lodash";
import { fCurrency } from "../../../../utils/formatNumber";

// ----------------------------------------------------------------------

export default function Highlight({
  title = "",
  total = "",
  icon = "",
  color = "",
  percent = "",
  value = "",
  subTitle = "",
  propCurrency,
}) {
  const currency = useSelector(
    (state) => state?.profileReducer?.user?.mto?.currency
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ width: 1, minWidth: 200 }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ position: "relative" }}
      >
        <Iconify icon={icon} width={24} sx={{ color, position: "absolute" }} />

        <CircularProgress
          variant="determinate"
          value={value}
          size={56}
          thickness={4}
          sx={{ color, opacity: 1 }}
        />

        <CircularProgress
          variant="determinate"
          value={100}
          size={56}
          thickness={4}
          sx={{
            top: 0,
            left: 0,
            // opacity: 0.48,
            position: "absolute",
            color: (theme) => alpha(theme.palette.grey[500], 0.16),
          }}
        />
      </Stack>

      <Stack spacing={0.5} sx={{ ml: 2 }}>
        <Typography variant="h6" sx={{ color: "#fff" }}>
          {_.startCase(title)}
        </Typography>

        {subTitle && (
          <Typography variant="subtitle2">
            {total}{" "}
            <Box
              component="span"
              sx={{ color: "text.secondary", typography: "body2" }}
            >
              {_.startCase(subTitle)}
            </Box>
          </Typography>
        )}
        <Typography variant="subtitle2" sx={{ color }}>
          {propCurrency ? `${propCurrency} ${value || 0}` : value}
        </Typography>
      </Stack>
    </Stack>
  );
}
