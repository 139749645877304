import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Button } from "rsuite";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Paper,
} from "@mui/material";
import { formatWithoutTimezone } from "../../utils/common";
import Label from "../Label";

const SettlementTransactionsModal = ({
  onClose,
  isOpen,
  text = "",
  loading = false,
}) => {
  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  function createData(id, date, customer, maker, checker, amount, status) {
    return {
      id,
      date: formatWithoutTimezone(date, "dd MMM yyyy HH:mm a"),
      customer,
      maker,
      checker,
      amount,
      status,
    };
  }

  const rows = [
    createData(
      1,
      "2024-06-20T00:29:26.261304-04:00",
      "Company A",
      "Aditya Aggarwal",
      "Sushanta",
      "550 USD",
      "Complete"
    ),
    createData(
        2,
        "2024-06-20T00:29:26.261304-04:00",
        "Company A",
        "Aditya Aggarwal",
        "Sushanta",
        "550 USD",
        "Awaiting Checker"
      ),
      createData(
        3,
        "2024-06-20T00:29:26.261304-04:00",
        "Company A",
        "Aditya Aggarwal",
        "Sushanta",
        "550 USD",
        "Rejected"
      ),
  ];

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={"md"}
        onClose={handleCloseModal}
        scroll={"body"}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>Settlement Transactions</DialogTitle>
        <DialogContent sx={{ paddingBottom: "0 !important" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 250 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Txn ID</TableCell>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Customer</TableCell>
                  <TableCell align="left">Maker</TableCell>
                  <TableCell align="left">Checker</TableCell>
                  <TableCell align="left">Amount</TableCell>
                  <TableCell align="left">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="left">{row.date}</TableCell>
                    <TableCell align="left">{row.customer}</TableCell>
                    <TableCell align="left">{row.maker}</TableCell>
                    <TableCell align="left">{row.checker}</TableCell>
                    <TableCell align="left">{row.amount}</TableCell>
                    <TableCell align="left">
                      <Label
                        variant={"ghost"}
                        color={
                          row?.status.toLowerCase() === "complete"
                            ? "complete"
                            : row?.status.toLowerCase() === "rejected"
                            ? "rejected"
                            : "application submitted"
                        }
                        sx={{ textTransform: "capitalize" }}
                      >
                        {row?.status}
                      </Label>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <div></div>
            <Button
              className={`btn action-button primary_color text-white`}
              appearance="primary"
              type={"submit"}
              onClick={onClose}
              loading={loading}
            >
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SettlementTransactionsModal;
