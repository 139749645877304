import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  useTheme,
} from "@mui/material";
import ButtonSecondary from "../ButtonSecondary";
import ButtonPrimary from "../ButtonPrimary";
import { DocumentCard } from "../documentCard";
import ContractDocumentCard from "../documentCard/ContractDocumentCard";

const ContractFilesModal = ({ onClose, isOpen, selectedContractFiles }) => {
  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };
  const theme = useTheme();
  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={"md"}
        onClose={handleCloseModal}
        scroll={"body"}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontSize: "24px",
            color: theme.palette.common.accentColor,
          }}
        >
          Contract Files
        </DialogTitle>
        <DialogContent
          sx={{ paddingTop: "5px !important", paddingBottom: "0 !important" }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(200px, 200px))",
              gridGap: "1rem",
              width: "100%",
            }}
          >
            {selectedContractFiles?.map((document, i) => {
              const docDetails = {
                id: document?.id,
                name: document?.document_name,
                document: document?.attachment_url,
              };
              return (
                <ContractDocumentCard
                  documents={docDetails}
                  docName={docDetails?.name}
                />
              );
            })}
          </Box>

          <DialogActions sx={{ justifyContent: "space-between" }}>
            <div></div>
            <ButtonPrimary onClick={handleCloseModal}>Close</ButtonPrimary>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ContractFilesModal;
