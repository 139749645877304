import React, { useCallback, useState } from "react";
import {
  Autocomplete,
  Box,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button } from "rsuite";

import { Stack, DialogActions, DialogContent } from "@mui/material";
import { TextFieldComponent, TextFieldSelect } from "../../components";
import { useSelector } from "react-redux";
import SearchNotFound from "../SearchNotFound";
import { CUSTOMER_PAYMENT } from "../../ApiUrl";
import { merchantApi } from "../../redux/api/apiConfig";
import { toast } from "react-toastify";

const validationSchema = yup.object({
  amount: yup
    .number("Enter amount")
    .min(0, "Please enter valid amount")
    .required("Amount is required!"),
});

const PaySupplier = ({ onClose, details, onSuccess, contractId }) => {
  const [loading, setLoading] = useState(false);

  const addClickHandler = async (values) => {
    const requestedData = {
      ...values,
      supplier: details?.cobo?.id,
      related_incoming_transfer: contractId,
    };

    try {
      setLoading(true);
      const response = await merchantApi.post(CUSTOMER_PAYMENT, requestedData);
      toast.success("Customer Payment Success");
      setTimeout(() => {
        formik.resetForm();
        onSuccess();
        onClose();
      }, 1000);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(err?.response?.data?.message || "Something went wrong");
    }
  };

  const formik = useFormik({
    initialValues: {
      customer: details?.cobo?.name,
      amount: "",
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: addClickHandler,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={1} direction={"column"} alignItems={"center"} mb={1}>
        <TextFieldComponent
          formik={formik}
          name={"customer"}
          type={"text"}
          label={"Customer"}
          disabled={true}
        />

        <TextFieldComponent
          formik={formik}
          name={"amount"}
          type={"number"}
          label={"Amount"}
          inputProps={{
            endAdornment: <InputAdornment position="end">USD</InputAdornment>,
          }}
        />
      </Stack>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button
          className="btn white-btn action-button "
          onClick={onClose}
          appearance="subtle"
        >
          Cancel
        </Button>
        <Button
          className={`btn action-button primary_color text-white`}
          appearance="primary"
          type={"submit"}
          disabled={!formik.isValid}
          loading={loading}
        >
          Create
        </Button>
      </DialogActions>
    </form>
  );
};

export default PaySupplier;
