import React from "react";
import { Box, Typography, LinearProgress, Tooltip } from "@mui/material";
import { formatWithoutTimezone } from "../../../../utils/common";

import { DataGrid } from "@mui/x-data-grid";
import { tableCellStyle } from "../../../../utils/common";

export default function ContractAuditLog({
  translist,
  onViewAction,
}) {
  // Custom component to handle "See more" functionality for the action list
  const ActionCell = ({ actions }) => {
    return (
      <Box
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          overflowY: "auto",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ul>
          <li>
            <Typography style={{ fontSize: "12px" }}>{actions[0]}</Typography>
          </li>
          {actions[1] && (
            <li>
              <Typography style={{ fontSize: "12px" }}>
                <>
                  {actions[1].slice(0, 20)}...{" "}
                  <span
                    onClick={() => onViewAction(actions)}
                    style={{
                      fontWeight: 600,
                      fontSize: "12px",
                      cursor: "pointer",
                      color: "#1a1f64",
                    }}
                  >
                    See more
                  </span>
                </>
              </Typography>
            </li>
          )}
        </ul>
      </Box>
    );
  };

  const createDataRow = (transaction) => {
    console.log("create action list", transaction?.actions);
    return {
      id: transaction?.id,
      date_time: formatWithoutTimezone(
        transaction?.created_date,
        "dd MMM yyyy HH:mm a"
      ),
      action: transaction?.actions,
      action_by:
        transaction?.modified_user?.name ||
        transaction?.modified_user?.email ||
        "--",
    };
  };

  const rows =
    translist?.results?.length > 0
      ? translist.results.map((transaction, i) => {
          return createDataRow(transaction);
        })
      : [];

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      maxWidth: 100,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip sx={{ cursor: "default" }} title={params.formattedValue}>
            <Typography variant="body2" style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "date_time",
      headerName: "Date/Time",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 400,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => <ActionCell actions={params.formattedValue} />,
    },
    {
      field: "action_by",
      headerName: "Action By",
      flex: 1,
      minWidth: 400,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
          borderRadius: "8px",
          // height: 825,
          width: "100%",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: "#667085",
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
            },
          },
          "& .header": {
            backgroundColor: "#F4F4F4",
            "& div": {
              "& div": {
                "& div": {
                  "& div": {
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: "0 0 8px 8px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
            width: "100%",
            cursor: "pointer",
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={rows}
          columns={columns}
          rowHeight={80}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination={false}
          hideFooter={true}
        />
      </Box>
    </>
  );
}
